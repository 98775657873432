import { signin, verifyOTP } from '../../api/auth.api';
import { useEffect, useRef, useState } from "react";
import { useMatches, useNavigate } from "react-router-dom"

import $ from "jquery";
import InputMask from 'react-input-mask';
import VehicleCarousel from '../../components/Garage/VehicleCarousel';
import { changeUserData } from "../../api/account.api";
import { getUserData } from "../../api/account.api";

function Settings() {
    let navigate = useNavigate();
    let matches = useMatches();
    let newName = useRef();
    let [vehicleOptions, setVehicleOptions] = useState([])
    let [userVehicles, setUserVehicles] = useState([])
    let [loadingVehicles, setLoadingVehicles] = useState(false);
    let handleOTPGeneration = () => {
        signin({ phoneNumber: localStorage.getItem('phoneNumber') }).then(resp => {
                if (resp.status === 200 || resp.status === 202) {
                    $('#otp').removeClass('invalidInput');
                    $('#fullName').attr('readonly', 'true')
                    $('#divOTPButtonContainer').css('display', 'flex')
                    $('#btnSaveChanges').prop('disabled', true)
                    $('#divSettingSaveContainer').css('display', 'none')
                    
                }
                else if (resp.status === 403) {
                    $('#otp').removeClass('invalidInput');
                    $('#otp').attr('placeholder', resp.msg)
                }
                else {
                    $('#otp').addClass('invalidInput');
                    $('#otp').val('');
                    $('#otp').attr('placeholder', resp.msg)
                }
            })
    }
    let handleOTPValidation = () => {
        let otp = $('#otp').val();
            let formdata = { phoneOTP: otp, userId: localStorage.getItem('userId'), phoneNumber: $('#phoneNumber').val().replaceAll('-', ''), vehicles: JSON.parse(localStorage.getItem('newUserVehicles')) }
            verifyOTP(formdata).then(resp => {
                if (resp.status === 200) {
                    $('#otp').removeClass('invalidInput');
                    changeUserData({ username: $('#fullName').val().trim(), phoneNumber: $('#phoneNumber').val().replaceAll('-', '').trim(), newVehicles: JSON.parse(localStorage.getItem('newUserVehicles')), delVehicles: JSON.parse(localStorage.getItem('delUserVehicles')) }).then(resp => {
                        if (resp.status === 200) {
                            localStorage.setItem('name', $('#fullName').val().trim())
                            localStorage.setItem('phoneNumber', $('#phoneNumber').val().replaceAll('-', '').trim())
                            localStorage.setItem('newUserVehicles', '[]')
                            localStorage.setItem('delUserVehicles','[]')
                            $('#fullName').attr('readonly', false)
                            $('#divOTPButtonContainer').css('display', 'none')
                            $('#divSettingSaveContainer').css('display', 'flex')
                        }
                        else {
                            console.log(resp.err);
                        }
                    })

                }
                else if (resp.status === 403) {
                    $('#otp').addClass('invalidInput');
                    $('#otp').val('')
                    $('#otp').attr('placeholder', 'User Does not Exist, Try Signing Up Again')
                }
                else {
                    $('#otp').addClass('invalidInput');
                    $('#otp').val('')
                    $('#otp').attr('placeholder', 'Invalid OTP, Try Again')
                }
            })
    }
    let handleOTPChange = (e) => {
        $('#btnVerifyOTP').prop('disabled', e.target.value.length!==4)
    }
    let handleLogout = () => {
        localStorage.clear();
        navigate('/client/auth/signin');
    }

    let handleSaveChanges = () => {
        $('#otp').removeClass('invalidInput');
        changeUserData({ username: $('#fullName').val().trim(), phoneNumber: $('#phoneNumber').val().replaceAll('-', '').trim(), newVehicles: JSON.parse(localStorage.getItem('newUserVehicles')), delVehicles: JSON.parse(localStorage.getItem('delUserVehicles')) }).then(resp => {
            if (resp.status === 200) {
                localStorage.setItem('name', $('#fullName').val().trim())
                localStorage.setItem('phoneNumber', $('#phoneNumber').val().replaceAll('-', '').trim())
                localStorage.setItem('newUserVehicles', '[]')
                localStorage.setItem('delUserVehicles','[]')
                $('#btnSaveChanges').prop('disabled', true)
            }
            else {
                console.log(resp.err);
            }
        })
    }
    useEffect(() => {
        getUserData().then(resp => {
            if (resp.status === 200) {
                localStorage.setItem('name',resp.user.username.trim())
                localStorage.setItem('phoneNumber',resp.user.phoneNumber.trim())
                setVehicleOptions(resp.vehicleOptions);
                setUserVehicles(resp.user.garage);
                setLoadingVehicles(true);
            }
            else {
                console.log(resp.err);
            }
        }).catch(err => {
            console.log(err);
        });
        $('#divAccountDetails').css('height', 'fit-content')
        $('#btnSaveChanges').prop('disabled', true).on('click', handleSaveChanges)
        $('#fullName').on('input', () => {
            newName.current = $('#fullName').val();
            $('#btnSaveChanges').prop('disabled', false).css('pointer-events', 'auto')
        }).val(localStorage.getItem('name'));
        $('#phoneNumber').val(localStorage.getItem('phoneNumber').replace(/^(\d{3})(\d{3})(\d+)$/, "$1-$2-$3"));
        $('#btnVerifyOTP').on('click', handleOTPValidation)
        localStorage.setItem('newUserVehicles', '[]')
        localStorage.setItem('delUserVehicles', '[]')
        $('#divAccountContentContainer').on('click', (e) => {
            if(e.target.id === 'divAccountContentContainer'){
                $('.accountDetailsModal').animate({ height: '0px' }, () => {
                    $('.accountDetailsModal').hide();
                    $('.accountDetailsContainer').hide().css('z-index', -1);
                    $("#divAccountContentContainer").prop("onclick", null).off("click");
                });
            }
        })
        // $(window).on('popstate', function(event) {
        //     $('.accountDetailsModal').animate({ height: '0px' }, () => {
        //         $('.accountDetailsModal').hide();
        //         $('.accountDetailsContainer').hide().css('z-index', -1);
        //         $("#divAccountContentContainer").prop("onclick", null).off("click");
        //     });
        //    });
        $('#divOTPButtonContainer').css('display','none')
        $('#divSettingSaveContainer').css('display','flex')
    }, [])
    return (
        <div id="divSettingsContainer" className="profileDetailContainer">
            <i className="fa-regular fa-circle-xmark cancelButton" onClick={() => {
                navigate(matches[1].pathname + '/map');
                $('.accountDetailsModal').animate({ height: '0px' }, () => {
                    $('.accountDetailsModal').hide();
                    $('.accountDetailsContainer').hide().css('z-index', -1);
                });
            }}></i>
            <div className="userSettingsContainer">
                <h3>Personal Information</h3>
                <div>
                    <p>Full Name</p>
                    <input id="fullName" type='text' placeholder="Full Name" className="customInput" />
                    <p>Phone Number</p>
                    <input id="phoneNumber" disabled="disabled" name="phoneNumber" className="customInput" type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" placeholder="Phone Number" autoComplete="off" data-error="Invalid Phone Number" data-norm="+91 000-000-0000" data-403='Phone Number Does Not Exist.' data-401='Try Again' data-409='Try Again' data-402='Try Again' />
                </div>
                <div>
                    <h3>My Garage</h3>
                    {/* {loadingVehicles && <VehicleCarousel vehicleOptions={vehicleOptions} userVehicles={userVehicles}/>} */}
                    <VehicleCarousel vehicleOptions={vehicleOptions} userVehicles={userVehicles}/>
                </div>
                <div id="divSettingSaveContainer">
                    <button id="btnSaveChanges" className="settingsButton"><span>Save</span></button>
                    <button id='btnLogout' className="settingsButton logout" onClick={handleLogout}>Logout</button>
                </div>
                <div id="divOTPButtonContainer">
                    <InputMask mask="9999" maskChar='' onChange={handleOTPChange}>
                        {(inputProps) => <input {...inputProps} id="otp" name="otp" type="text" className="customInput" placeholder="Enter OTP" autoComplete="off" data-error="Invalid OTP" data-norm="Enter OTP" data-401='Try Again' data-409='Try Again' data-402='Try Again' />}
                    </InputMask>
                    <button id='btnVerifyOTP' className="settingsButton" disabled="disabled">Verify</button>
                </div>
            </div>
        </div>
    )
}

export default Settings