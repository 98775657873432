import { useEffect, useRef } from 'react';

import $ from 'jquery'
import { createOrder } from '../../api/account.api';
import { getWallet } from '../../api/account.api';

function PaymentButton(props) {
    const easebuzzRef = useRef();
    let displayPaymentPage = async () => {
        let topupAmountRequest = $('#topupAmount').val();
        topupAmountRequest = Number(topupAmountRequest.replace(/[^0-9.-]+/g, ""));
        if (topupAmountRequest >= 10 && topupAmountRequest <= 10000 && localStorage.getItem('phoneNumber')) {
            $('#topupAmount').removeClass('invalidInput');
            $('#topupAmount').attr('placeholder', '')

            let resp = await createOrder(topupAmountRequest, localStorage.getItem('phoneNumber'), localStorage.getItem('name'));

            var options = {
                access_key: resp.data, // access key received via Initiate Payment
                onResponse: (response) => {
                    if (response.status === 'success') {
                        setTimeout(() => {
                            $('#topupAmount').addClass('successfulInput');
                            $('#topupAmount').val('');
                            $('#topupAmount').attr('placeholder', 'Payment Success');
                            $('.addTextField').hide();
                            $('.topupDefaults').show();
                            getWallet().then((resp) => {
                                if (resp.wallet) {
                                    let length = ("" + Math.floor(resp.wallet.balance)).length;
                                    let textSize = 75 - (length * 5);
                                    $('.walletBalance>h1').html('₹' + Math.floor(resp.wallet.balance));
                                    $(".walletBalance>h1").css("fontSize", textSize);
                                    props.setTransactionList(resp.transactionList);
                                    $('#divWalletContainer').fadeOut(500, () => {

                                        let height = (window.screen.width > 430) ? "415px" : "350px";
                                        height = (window.screen.height > 640) ? height : "267px";

                                        $("#chargeInvoice").hide();
                                        $("#reservationInvoice").hide();
                                        $('.accountDetailsModal').animate({ height: height, maxHeight: "100%" })
                                        $(".invoiceHead > h1").text("Top-Up");
                                        $("#date > .val").text(resp.transactionList[0].transactionStartDate);
                                        $("#paymentId > .val").text(resp.transactionList[0].paymentId);
                                        $("#referenceId > .val").text(resp.transactionList[0].id);
                                        $("#rechargedAmount > .val").text('₹' + (parseFloat(resp.transactionList[0].amount) * 1.18).toFixed(2));
                                        $("#cgst > .val").text('-₹' + parseFloat((resp.transactionList[0].amount * 0.09)).toFixed(2));
                                        $("#sgst > .val").text('-₹' + parseFloat((resp.transactionList[0].amount * 0.09)).toFixed(2));
                                        $("#addedAmount > .val").text('₹' + parseFloat(resp.transactionList[0].amount).toFixed(2));
                                        $("#paymentStatus >.val").text("Success");
                                        $("#paymentMethod >.val").text("E-Wallet");
                                        $('#walletRecharge').fadeIn();
                                    });
                                }
                                else {
                                    $('.walletBalance>h1').html('₹' + 0);
                                }
                            }).catch(err => {
                                console.log(err);
                            })
                        }, 2000);
                    }
                    else {
                        $('#topupAmount').addClass('invalidInput');
                        $('#topupAmount').val('');
                        $('#topupAmount').attr('placeholder', 'Payment Failed');
                    }
                },
                theme: "#123456" // color hex
            }
            easebuzzRef.current.initiatePayment(options);
        }
        else if (topupAmountRequest < 10) {
            $('#topupAmount').addClass('invalidInput');
            $('#topupAmount').val('');
            $('#topupAmount').attr('placeholder', 'Minimum Value ₹10')
            $('.addTextField').hide();
            $('.topupDefaults').show();
        }
        else {
            $('#topupAmount').addClass('invalidInput');
            $('#topupAmount').val('');
            $('#topupAmount').attr('placeholder', 'Maximum Value ₹10000')
            $('.addTextField').hide();
            $('.topupDefaults').show();
        }
    }

    useEffect(() => {
        const easebuzzCheckout = new window.EasebuzzCheckout(process.env.REACT_APP_EASEBUZZ_KEY, process.env.REACT_APP_EASEBUZZ_ENV);
        easebuzzRef.current = easebuzzCheckout;
    }, []);

    return (
        <div className="iconContainer deposit" onClick={() => { displayPaymentPage() }}>
            <i className="fa-solid fa-plus"></i>
        </div>
    )

}

export default PaymentButton