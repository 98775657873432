import { signin, signup, verifyOTP } from '../../api/auth.api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import $ from 'jquery';
import InputMask from 'react-input-mask';
import { getSession } from '../../api/device.api';
import spotlight_logo from '../../assets/Spotlight_Logo.svg'
import { useEffect } from 'react';
import { useState } from 'react';
import wavefuel_logo from '../../assets/powered_by_wavefuel.svg'

function Signin() {
    let navigate = useNavigate();
    let urlParams = useParams();
    let location = useLocation();
    let handleAuthentication = (isResend = false) => {
        let phoneNumber = $('#phoneNumber').val().replaceAll('-', '');
        let re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
        $('#btnSignin').addClass('disabledButton');
        $('#btnSignin').prop('disabled', true);
        if (phoneNumber.length === 10 && re.test(phoneNumber)) {
            let formdata = { phoneNumber: phoneNumber, isResend: isResend }
            signin(formdata).then(resp => {
                if (resp.status === 200 || resp.status === 202) {
                    renderOTPForm();
                    $('#phoneNumber').removeClass('invalidInput');
                    $('#btnSignin').prop('disabled', true).addClass('disabledButton');
                    localStorage.setItem("userId", resp.userId)
                }
                else if (resp.status === 403) {
                    $('#btnSignin').prop('disabled', true).addClass('disabledButton');
                    $('#phoneNumber').removeClass('invalidInput');
                    $('#phoneNumber').attr('placeholder', resp.msg)
                    renderSignupForm();
                }
                else {
                    $('#btnSignin').prop('disabled', false).removeClass('disabledButton');
                    $('#phoneNumber').addClass('invalidInput');
                    $('#phoneNumber').val('');
                    $('#phoneNumber').attr('placeholder', resp.msg)
                }
            })
        }
        else {
            $('#phoneNumber').addClass('invalidInput');
            $('#phoneNumber').attr('placeholder', 'Invalid Input, Try Again')
        }
    }
    let renderSigninForm = () => {
        $('.parentSignupContainer .formContainer > form').css({})
        $('#divOTPInput').animate({ height: "0%", opacity: 0 }, () => {
            $('#divOTPInput').hide();
            $('#resendOTP').hide();
            $('#otp').val('');
        })
        $('#divOTPValidatorButtons').animate({ opacity: 0 }, () => {
            $('#divOTPValidatorButtons').css({ display: 'none' });
        });
        $('#divPhoneNumberInput').animate({ opacity: 1 }, () => {
            $('#divPhoneNumberInput').css({ display: 'flex', height: 'auto' });
        })
        $('#btnSignin').animate({ opacity: 1 }, () => {
            $('#btnSignin').show();
        })
        $('#btnSignin').prop('disabled', false).removeClass('disabledButton');
        $('#pMessage').css('display', 'none')
        $('#h1Message').text("Welcome").css({ textAlign: 'center' }).animate({ opacity: 1 });
        $('#resendOTP').css({ 'display': 'none', 'opacity': '1' })
    }
    let renderOTPForm = () => {
        $('.parentSignupContainer .formContainer > div').css({ height: 'auto' })
        $('#divPhoneNumberInput').animate({ height: '0%', opacity: 0 }, () => {
            $('#divPhoneNumberInput').hide();
            $('#divOTPInput').show();
            $('#divOTPInput').animate({ height: "100%", opacity: 1 });
        })
        $('#btnSignin').animate({ opacity: 0 }, () => {
            $('#btnSignin').hide();
            $('#divOTPValidatorButtons').animate({ opacity: 1 }, () => {
                $('#divOTPValidatorButtons').css({ display: 'flex' });
            });
        })
        $('.signupElements').addClass('hide');
        $('.signinElements').removeClass('hide')
        // $('#divPhoneNumberInput').css('display','flex')
        $('#resendOTP').css({ 'display': 'block', 'opacity': '1' })
        $('#btnSignin').hide();
        $('#btnSignup').hide();
    }
    let renderSignupForm = () => {
        $('.signupElements').removeClass('hide');
        $('.signinElements').addClass('hide');
        $('#username').val('');
        $('.carTypeSelection i').removeClass('active');
        $('#carTypeSelection').val('');
        $('#agreement > label > input').prop("checked", false);
        $('#h1Message').animate({ opacity: 0 });
        $('#btnSignin').animate({ opacity: 0 }, () => {
            $('#btnSignin').hide();
            $('#btnSignup').show();
        })
        $('.formContainer>form').animate({ opacity: 1 }, () => {
            $('.signupElements').animate({ height: "100%", opacity: 1 })
            $('.signupElements').css({ display: 'flex', justifyContent: "center" });
            $('#h1Message').text("Lets Setup Your Account").css({ textAlign: 'center' }).animate({ opacity: 1 });
            $('#pMessage').show().css({ textAlign: 'center' }).animate({ opacity: 1 });
            $('#btnSignup').animate({ opacity: 1 }, () => {
            })
        })
    }
    let handleSignup = () => {
        let phoneNumber = $('#phoneNumber').val().replaceAll('-', '');
        let re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
        let reusername = /^[a-z ,.'-]+$/i
        let username = $('#username').val().trim();
        let vehicleOwnerType = $('#vehicleOwnerType').val();
        if (phoneNumber.length !== 10 || !re.test(phoneNumber) || !$("input[name=agreement]")[0].checked)
            return;
        else if (username && reusername.test(username) && vehicleOwnerType) {
            let formdata = { phoneNumber: phoneNumber, username: username, vehicleOwnerType: vehicleOwnerType }
            signup(formdata).then(resp => {
                if (resp.status === 200) {
                    renderOTPForm();
                    $('#phoneNumber').removeClass('invalidInput');
                    $('#username').removeClass('invalidInput');
                    $('#vehicleOwnerType').removeClass('invalidInput');
                    $('#pMessage').css('display', 'none');
                    $('#h1Message').text("Welcome").css({ textAlign: 'center' }).animate({ opacity: 1 });
                    localStorage.setItem("userId", resp.userId)
                }
                else if (resp.status === 403) {
                    $('#phoneNumber').removeClass('invalidInput');
                    $('#username').removeClass('invalidInput');
                    $('#vehicleOwnerType').removeClass('invalidInput');
                    renderSignupForm();
                }
                else {
                    $('#phoneNumber').addClass('invalidInput');
                    $('#username').addClass('invalidInput');
                    $('#phoneNumber').attr('placeholder', 'Something went wrong, try a different Number')
                }
            })
        }
        else {
            if (!vehicleOwnerType) {
                $('.carTypeSelection i').addClass('invalidInput');
                $('#username').val('')
                $('#username').addClass('invalidInput');
                $('#username').attr('placeholder', 'Select a Vehicle')
            }
            else {
                $('#phoneNumber').addClass('invalidInput');
                $('#username').addClass('invalidInput');
                $('#phoneNumber').attr('placeholder', 'Invalid Input, Try Again')
                $('#username').attr('placeholder', 'Invalid Input, Try Again')
            }
        }
    }
    let handleOTPValidation = () => {
        let otp = $('#otp').val();
        otp = otp.substring(0, 4);
        if (otp.length === 4) {
            let formdata = { phoneOTP: otp, userId: localStorage.getItem("userId") }
            verifyOTP(formdata).then(resp => {
                if (resp.status === 200) {
                    $('#otp').removeClass('invalidInput');
                    localStorage.setItem('userId', resp.user._id);
                    localStorage.setItem('phoneNumber', resp.user.phoneNumber.trim());
                    localStorage.setItem('name', resp.user.username.trim());
                    localStorage.setItem('customer', JSON.stringify(resp.customer));
                    localStorage.setItem('authorization', resp.auth)
                    localStorage.setItem('exp', resp.exp);
                    if (resp.user.activeSession) {
                        getSession(resp.user.activeSession).then(sessionResp => {
                            if (sessionResp.status === 200) {
                                localStorage.setItem('activeSession', resp.user.activeSession);
                                navigate('/client/account/' + resp.user._id, { replace: true, state: { id: sessionResp.chargingStation.deviceId, session: sessionResp.session, activeDevice: true, originalRoute: location.pathname, wallet: resp.user.wallet, customer: resp.user.customer } });
                            }
                            else {
                                console.log('Invalid Active Session')
                                navigate('/client/account/' + resp.user._id, { replace: true });
                            }
                        })
                    }
                    else if (urlParams.deviceId) navigate('/client/account/' + resp.user._id, { replace: true, state: { id: urlParams.deviceId, activeDevice: true, originalRoute: location.pathname, wallet: resp.user.wallet, customer: resp.user.customer } })
                    else { navigate('/client/account/' + resp.user._id, { replace: true }); }
                }
                else if (resp.status === 403) {
                    $('#otp').addClass('invalidInput');
                    $('#otp').val('')
                    $('#otp').attr('placeholder', 'User Does not Exist, Try Signing Up Again')
                }
                else {
                    $('#otp').addClass('invalidInput');
                    $('#otp').val('')
                    $('#otp').attr('placeholder', 'Invalid OTP, Try Again')
                }
            })
        }
        else {
            $('#otp').addClass('invalidInput');
            $('#otp').val('')
            $('#otp').attr('placeholder', 'The OTP should be 4 Characters');
        }
    }

    let handleNumberInput = (e) => {
        let re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
        if (e.currentTarget.value.length === 12 && re.test(e.currentTarget.value)) {
            $('#btnSignin').removeClass('disabledButton');
            $('#btnSignin').prop('disabled', false);
        }
        else if (e.currentTarget.value.length > 12) {
            let val = e.currentTarget.value.substring(0, 12);
            $('#phoneNumber').val(val);
            if (re.test($('#phoneNumber').val())) {
                $('#btnSignin').removeClass('disabledButton');
                $('#btnSignin').prop('disabled', false);
            }
            else {
                $('#btnSignin').addClass('disabledButton');
                $('#btnSignin').prop('disabled', true);
            }
        }
        else {
            $('#btnSignin').addClass('disabledButton');
            $('#btnSignin').prop('disabled', true);
        }
    }

    useEffect(() => {
        if (localStorage.getItem("userId")) {
            if (urlParams.deviceId) navigate('/client/account/' + localStorage.getItem("userId"), { replace: true, state: { id: urlParams.deviceId, activeDevice: true, originalRoute: location.pathname } });
            else navigate('/client/account/' + localStorage.getItem("userId"), { replace: true });
        }
        $('body').on('keypress', (e) => {
            if (e.code === 'Enter' || e.key === 'Enter' || e.keyCode === 13 || e.which === 13) {
                e.preventDefault();
                e.stopPropagation();
            }
        })
        $('.carTypeSelection i').on('click', (e) => {
            $('#vehicleOwnerType').val(e.currentTarget.dataset.val);
            $('.carTypeSelection i').removeClass('active');
            $('.carTypeSelection i').removeClass('invalidInput');
            $('#username').removeClass('invalidInput');
            $('#username').attr('placeholder', 'John Doe')
            $(e.currentTarget).addClass('active');
        })
        $('#phoneNumber').on('blur input', handleNumberInput).on('keypress', (e) => {
            if (e.code === 'Enter' || e.key === 'Enter' || e.keyCode === 13 || e.which === 13) {
                e.preventDefault();
                e.stopPropagation();
                handleAuthentication();
            }
        })
        $('#otp').on('input', (e) => {
            if (e.currentTarget.value.length >= 4) {
                $('#btnOTPValidator').removeClass('disabledButton');
                $('#btnOTPValidator').prop('disabled', false);
            }
            else {
                $('#btnOTPValidator').prop('disabled', true);
            }
        }).on('keypress', (e) => {
            if (e.code === 'Enter' || e.key === 'Enter' || e.keyCode === 13 || e.which === 13) {
                e.preventDefault();
                e.stopPropagation();
                handleOTPValidation();
            }
        })
        $("form").on('submit', function (e) {
            e.preventDefault();
            e.stopPropagation();
        });
        $('#pMessage, #btnSignup').css({ 'display': 'none', 'opacity': '0' })
        $('#resendOTP').css({ 'display': 'none', 'opacity': '0' })
        $('#divOTPInput').css({ 'display': "none", 'opacity': 0, 'height': "0%" })
        $('#divNameInput, #divVehicleInput, #agreement').css({ 'display': "none" })
        $('#agreement > label > input').css({ 'width': 'unset' })
        $('#divOTPValidatorButtons').css({ 'display': "none", 'opacity': '0', 'padding-left': "10px" })
        $('.parentLogo').css({ 'margin-bottom': '15px' })
        return (() => {
            $("body").off("keyup", (e) => {
                if (e.code === 'Enter' || e.key === 'Enter' || e.keyCode === 13 || e.which === 13) {
                    e.preventDefault();
                    e.stopPropagation();
                    navigate('account/' + localStorage.getItem("userId"))
                }
            });
            $('.carTypeSelection i').off('click', (e) => {
                $('#vehicleOwnerType').val(e.currentTarget.dataset.val);
            })
        })
    }, [])
    return (
        <div className='parentSignupContainer justifyAround'>
            <img className='logo signinElements' src={spotlight_logo} alt="" />
            <div className='formContainer'>
                <p id="pMessage" >
                    Looks like this is your first time.
                </p>
                <h1 id="h1Message">Welcome</h1>
                <form className="formInputs">
                    <div id="divPhoneNumberInput" className='signinElements'>
                        <p>
                            Please Enter your Phone Number
                        </p>
                        <InputMask mask="999-999-9999" maskChar=''>
                            {(inputProps) => <input {...inputProps} id="phoneNumber" name="phoneNumber" type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" placeholder="+91 999-999-9999" autoComplete="on" data-error="Invalid Phone Number" data-norm="+91 000-000-0000" data-403='Phone Number Does Not Exist.' data-401='Try Again' data-409='Try Again' data-402='Try Again' />}
                        </InputMask>
                    </div>
                    <div id="divOTPInput" className='signinElements'>
                        <p>
                            Please Enter the Generated OTP
                        </p>
                        <div className='row'>
                            <InputMask mask="9999" maskChar=''>
                                {(inputProps) => <input {...inputProps} id="otp" name="otp" type="text" placeholder="Enter OTP" autoComplete="on" data-error="Invalid OTP" data-norm="Enter OTP" data-401='Try Again' data-409='Try Again' data-402='Try Again' />}
                            </InputMask>
                        </div>
                    </div>
                    <div id="divNameInput" className='signupElements'>
                        <p>
                            Please Enter your Full Name
                        </p>
                        <InputMask mask="" maskChar='' className=''>
                            {(inputProps) => <input {...inputProps} id="username" name="username" type="text" maxLength="30" placeholder="John Doe" autoComplete="on" data-error="Invalid name given." data-norm="John Doe" data-401='Try Again' data-409='Try Again' data-402='Try Again' />}
                        </InputMask>
                    </div>
                    <div id="divVehicleInput" className='signupElements'>
                        <p>
                            What Type of Vehicle do you Use?
                        </p>
                        <div className='carTypeSelection'>
                            <i className="fa-solid fa-motorcycle" data-val="Bike"></i>
                            <i className="fa-solid fa-car-side" data-val="Sedan"></i>
                            <i className="fa-solid fa-truck-pickup" data-val="SUV"></i>
                            <i className="fa-solid fa-truck" data-val="Truck"></i>
                            <i className="fa-solid fa-bus-simple" data-val="Bus"></i>
                        </div>
                    </div>
                    <div id="agreement" className='signupElements'><label htmlFor="agreement"><input type="checkbox" name="agreement" /> I agree to the <a href="https://wavefuel.in/terms-and-conditions.html" target='_blank'>Terms and Conditions</a> and <a href="https://wavefuel.in/privacy-policy.html" target='_blank'>Privacy Policy</a>.</label></div>
                    <input className='hide' id="vehicleOwnerType" name="vehicleOwnerType" type="text" />
                    <button id="btnSignin" className='disabledButton ' onClick={() => { handleAuthentication() }}>Enter</button>
                    <button id="btnSignup" className='' onClick={() => { handleSignup() }}>Submit</button>
                    <div id="divOTPValidatorButtons" className='buttonsContainer'>
                        <button id="btnCancel" className='cancelButton' onClick={() => { renderSigninForm() }}><i className="fa-solid fa-arrow-left"></i></button>
                        <button id="btnOTPValidator" className='disabledButton' onClick={() => { handleOTPValidation() }}>Submit</button>
                    </div>
                    <p id="resendOTP" className='signinElements'>Not Received yet? <span onClick={() => { handleAuthentication(true) }}>Resend</span></p>
                </form>
            </div>
            <img className='parentLogo signinElements' src={wavefuel_logo} alt="" />
        </div>
    )
}

export default Signin
