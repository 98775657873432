import { ProtectedAPI } from './index'

export async function remoteStart(stationId, connectorId) {
    let url = '/device/transaction/remotestart/' + localStorage.getItem('userId') + '/' + stationId;
    let options = {};
    options.headers = { 'Access-Control-Allow-Origin': '*' };
    options.body = {
        idTag: localStorage.getItem('phoneNumber'),
        connectorId: connectorId
    }
    options.method = "POST";

    let resp = ProtectedAPI(url, options)
    return resp
}

export async function cancelSessionAPI(stationId) {
    let url = '/device/transaction/cancel/reservation/' + localStorage.getItem('userId') + '/' + stationId;
    let options = {};
    options.headers = { 'Access-Control-Allow-Origin': '*' };
    options.method = "POST";

    let resp = ProtectedAPI(url, options)
    return resp
}

export async function remoteStop(stationId, transactionId, connectorId) {
    let url = '/device/transaction/remotestop/' + localStorage.getItem('userId') + '/' + stationId;
    let options = {};
    options.headers = { 'Access-Control-Allow-Origin': '*' };
    options.body = {
        "transactionId": transactionId,
        "connectorId": connectorId,
    }
    options.method = "POST";

    let resp = ProtectedAPI(url, options)
    return resp
}

export async function getSession(sessionId) {
    let url = '/device/get/session/' + localStorage.getItem('userId') + '/' + sessionId;
    let options = {};
    options.headers = { 'Access-Control-Allow-Origin': '*' };
    options.method = "GET";

    let resp = ProtectedAPI(url, options)
    return resp
}


export async function getMeterValues(transactionId) {
    let url = '/device/transaction/getMeterValue/' + localStorage.getItem('userId') + '/' + transactionId;
    let options = {};
    options.headers = { 'Access-Control-Allow-Origin': '*' };
    options.body = {
        "transactionId": transactionId
    }
    options.method = "POST";

    let resp = ProtectedAPI(url, options)
    return resp
}

export function checkConnectorStatus(stationId, connectorNumber, idTag, userId) {
    let url = '/device/check/connector/' + localStorage.getItem('userId') + '/' + stationId;
    let options = {};
    options.headers = { 'Access-Control-Allow-Origin': '*' };
    options.body = {
        "connectorNumber": connectorNumber,
        idTag: idTag,
        userId: userId
    }
    options.method = "POST";

    let resp = ProtectedAPI(url, options)
    return resp
}