import { useEffect, useState } from 'react';

import AuthGuard from '../../api/storage/AuthGuard';
import GeoMap from './GeoMap';
import Loader from '../../components/Loader/Loader';
import NavHeader from '../../components/NavHeader';
import {
    Outlet,
} from "react-router-dom";
import { ProtectedAPI } from '../../api';
import { getSession } from '../../api/device.api';
import { removeUserActiveSession } from '../../api/account.api'
import { useLocation } from 'react-router-dom';

function AccountLayout() {
    const [overviewContent, setOverviewContent] = useState({ total: 0, active: 0, inactive: 0, breakdown: 0 });
    // let nullProject = {
    //     projectName: null,
    //     projectType: null,
    //     devices: [],
    //     projectStatus: null,
    //     projectDescription: null,
    //     location: {lat: 78, lon: 19 },
    //     address: null,
    //     installationDate: null,
    //     locationCategory: null,
    //     accessCategory: null,
    //     serviceFee: { price: 10, unit: 'INR' }, 
    //     sessionTariff: { price: 10, unit: 'INR' },
    //     reservationFee: { price: 10, unit: 'INR' },
    // }
    const [projects, setProjects] = useState([]);
    const [activeChargePoint, setActiveChargePoint] = useState(null);
    const [rendered, setRendered] = useState(false);
    let location = useLocation();
    const [locationState, setLocationState] = useState(location?.state);

    let handleRenderChargePoints = () => {
        ProtectedAPI('/device/get/all/' + localStorage.getItem('userId'), { method: "GET" }).then(resp => {
            let device;
            let devices = [];
            let projects = resp.projects;
            resp.projects.forEach(element => {
                devices = devices.concat(element.devices)
            });
            let assets = devices.map(e => e.assetId);
            let activeDevices = devices.filter(e => e.deviceStatus === 'Active');
            let inactiveDevices = devices.filter(e => e.deviceStatus === 'Inactive');
            let breakdownDevices = devices.filter(e => e.deviceStatus === 'Breakdown');
            setOverviewContent({ total: devices.length, active: activeDevices.length, inactive: inactiveDevices.length, breakdown: breakdownDevices.length })
            setProjects(resp.projects);
            if (localStorage.getItem('userId')) {
                ProtectedAPI('/user/get/' + localStorage.getItem('userId'), { method: "GET" }).then(resp => {
                    if (resp.status === 200) {
                        if (resp.user.activeSession) {
                            getSession(resp.user.activeSession).then(sessionResp => {
                                if (sessionResp.status === 200 && sessionResp.session.status === 'open') {
                                    localStorage.setItem('activeSession', resp.user.activeSession);
                                    device = devices.find(e => e._id === sessionResp.chargingStation.deviceId)
                                    setLocationState({ ...location?.state, id: sessionResp.chargingStation.deviceId, session: sessionResp.session, activeDevice: true, originalRoute: location.pathname, wallet: resp.user.wallet, customer: resp.user.customer })
                                    let project = projects.filter(e => e.devices.filter(e => e._id === device._id).length !== 0)[0]
                                    setActiveChargePoint({ device: device, asset: device.assetId, project: project, connectorId: sessionResp.connectorId });
                                    setRendered(true);
                                }
                                else {
                                    if (sessionResp.session.status !== 'open') {
                                        removeUserActiveSession();
                                    }
                                    setActiveChargePoint(null);
                                    localStorage.removeItem('activeSession');
                                    console.log('Invalid Active Session')
                                    setRendered(true);
                                }
                            })
                        }
                        else if (resp.user.reservedCharger) {
                            let device = devices.find(e => e.assetId._id === resp.user.reservedCharger);
                            setActiveChargePoint({ device: device, asset: device.assetId, project: projects.filter(e => e.devices.filter(e => e._id === device._id).length !== 0)[0], connectorId: resp.user.reservedConnector });
                            setRendered(true);
                        }
                        else {
                            if (location.state) {
                                device = devices.find(e => e._id === location.state.id)
                                setActiveChargePoint({ device: device, asset: device.assetId, project: projects.filter(e => e.devices.filter(e => e._id === device._id).length !== 0)[0], connectorId: 1 });
                            }
                            localStorage.removeItem('activeSession');
                            setRendered(true);
                        }
                    }
                    else {
                        setActiveChargePoint(null);
                        setRendered(true);
                    }
                });
            }
            return resp;
        }).catch(err => {
            console.log(err);
        })
    }
    let renderOverviewContent = () => {
        if (rendered) {
            return (
                <GeoMap projects={projects} overview={overviewContent} state={location.state} activeChargePoint={activeChargePoint} />
            )
        }
        else {
            return (
                <Loader />
            )
        }
    }
    useEffect(() => {
        if (!rendered) handleRenderChargePoints();
    }, [locationState])
    return (
        <div id="divContent" className='contentContainer'>
            <NavHeader />
            {renderOverviewContent()}
            <div id="divAccountContentContainer" className='accountDetailsContainer'>
                <div id="divAccountDetails" className='accountDetailsModal'>
                    <Outlet />
                </div>
            </div>
            <script src="https://ebz-static.s3.ap-south-1.amazonaws.com/easecheckout/easebuzz-checkout.js"></script>
        </div>
    )
}

function Account() {
    return (
        <AuthGuard component={AccountLayout} />
    )
}

export default Account