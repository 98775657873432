import { useEffect, useRef, useState } from 'react';

import $ from 'jquery';

function Invoice(props) {

    useEffect(() => {
        if (props.details.message)
            $('.errorInfo > p').html(props.details.message);
    }, [props.details.message])

    return (<div id="stationInvoice" className='stationInvoiceContainer'>
        <div className='invoiceSection'>
            {props.details.message && <div className='errorInfo'><i className="fa-solid fa-triangle-exclamation"></i><p>{props.details.message}</p></div>}
            <h1>Session Details</h1>
            <div className='sectionDetails'>
                <div id="sessionDuration">
                    <p>Duration</p>
                    <div className='divider'></div>
                    <p className='val'>{props.details.duration}</p>
                </div>
                <div id="sessionEnergy">
                    <p>Energy Delivered</p>
                    <div className='divider'></div>
                    <p className='val'>{props.details.energy}</p>
                </div>
                <div id="sessionStartTime">
                    <p>Session Start Time</p>
                    <div className='divider'></div>
                    <p className='val'>{props.details.startTime}</p>
                </div>
                <div id="sessionTariff">
                    <p>Session Tariff (per kWh)</p>
                    <div className='divider'></div>
                    <p className='val'>{props.details.tariff}</p>
                </div>
            </div>
        </div>
        <div className='invoiceSection'>
            <h1>Billing Details</h1>
            <div className='sectionDetails'>
                <div id="serviceFee">
                    <p>Service Fee</p>
                    <div className='divider'></div>
                    <p className='val'>{props.details.serviceFee}</p>
                </div>
                <div id="reservationFee">
                    <p>Reservation Fee</p>
                    <div className='divider'></div>
                    <p className='val'>{props.details.reservationFee}</p>
                </div>
                <div id="invoiceFee">
                    <p>Charging Fee</p>
                    <div className='divider'></div>
                    <p className='val'>{props.details.fee}</p>
                </div>
            </div>
        </div>
        <div className='invoiceSection'>
            <h1>Payment Details</h1>
            <div className='sectionDetails'>
                <div id="invoiceAmount">
                    <p>Total Amount</p>
                    <div className='divider'></div>
                    <p className='val'>{props.details.amount}</p>
                </div>
                <div id="invoicePaymentStatus">
                    <p>Payment Status</p>
                    <div className='divider'></div>
                    <p className='val'>{props.details.paymentStatus}</p>
                </div>
                <div id="invoicePaymentMethod">
                    <p>Payment Method</p>
                    <div className='divider'></div>
                    <p className='val'>{props.details.paymentMethod}</p>
                </div>
            </div>
        </div>
    </div>)
}

export default Invoice