import $ from 'jquery'
import vehicleLogo from '../../assets/settings/MG-SE.jpg'

function VehicleCard(props) {

    let handleDeleteVehicle = (e) => {
        let delVehicle = $(e.target).siblings()[0].innerHTML
        props.deleteVehicleCallBack(delVehicle)
        let ind;
        let vehicles = localStorage.getItem("newUserVehicles")
    }

    return (
            <div className="card vehicleCard">
                <div>
                    <h4>{props.details.name}</h4>
                    <i className="fa-regular fa-circle-xmark" onClick={handleDeleteVehicle}></i>
                </div>
                <div><p className="vehicleNumber">{props.details.bodyType !== undefined ? props.details.bodyType : "N/A"}</p></div>
                <div>
                    <div className="col">
                        <p>Range</p>
                        <p><b>{props.details.maxRange !== undefined ? props.details.maxRange : "N/A"}</b> KM</p>
                    </div>
                    <div className="col">
                        <p>Capacity</p>
                        <p><b>{props.details.capacity !== undefined ? props.details.capacity : "N/A"}</b> kWh</p>
                    </div>
                    <div className="col">
                        <p>Last Seen</p>
                        <p><b>30</b> Days</p>
                    </div>
                </div>
                <div>
                <img src={'https://d3mnjagmjt3x15.cloudfront.net/' + props.details.fileName} alt={props.details._id}></img>
                </div>
            </div>
    )
}

export default VehicleCard