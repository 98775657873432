import "swiper/css";
import "swiper/css/pagination";
import 'swiper/css/scrollbar';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, FreeMode } from "swiper";
import { Scrollbar } from 'swiper';

import React, { useEffect, useRef, useState } from "react";
import { getVehicle } from "../../api/account.api";
import $ from "jquery";
import VehicleCard from "./VehicleCard";

import { ReactSearchAutocomplete } from 'react-search-autocomplete'

import "./VehicleCarousel.scss"
function VehicleCarousel(props) {
    let [vehicleList, setVehicleList] = useState([])
    let [deletedVehicle, setDeletedVehicle] = useState('')
    let [isDeleted, setIsDeleted] = useState(false);
    let value = useRef('')
    function submitForm() {
        let vehicles = JSON.parse(localStorage.getItem('newUserVehicles'))
        if (!vehicles.includes(value.current)) {
            for (let i in vehicleList) {
                if (vehicleList[i].key === value.current)
                    return;
            }
            getVehicle({ _id: value.current }).then((resp) => {
                setVehicleList(vehicleList.concat(<SwiperSlide key={resp.vehicle.name}><VehicleCard details={resp.vehicle} deleteVehicleCallBack={deleteVehicleCallBack} /> </SwiperSlide>))
                vehicles.push(value.current)
                localStorage.setItem('newUserVehicles', JSON.stringify(vehicles))
                $('#btnSaveChanges').prop('disabled', false)
                $('#btnAddVehicle').animate({ width: '0%' }, 50, 'linear', () => {
                    $('#btnAddVehicle').css('display', 'none');
                    $("#divAutocompleteContainer").css('border-radius', '10px')
                })
                $('#divAutocompleteContainer .clear-icon')[0].click()
            })
        }
    }

    const deleteVehicleCallBack = (delVehicle) => {
        setDeletedVehicle(delVehicle);
        setIsDeleted((e) => { return !e });
    }

    const handleSearch = (search) => {
        if (search === '') {
            $('#btnAddVehicle').animate({ width: '0%' }, 50, 'linear', () => {
                $('#btnAddVehicle').css('display', 'none');
                $("#divAutocompleteContainer").css('border-radius', '10px')
            })
        }
    }

    const handleOnSelect = (item) => {
        if ($('#btnAddVehicle').css('display') !== 'none')
            return
        $('#btnAddVehicle').css('display', 'block');
        $("#divAutocompleteContainer").css('border-radius', '10px 0px 0px 10px')
        $('#btnAddVehicle').animate({ width: '25%' }, 50, 'linear')
        value.current = item.name;
    }

    useEffect(() => {
        if (deletedVehicle === '')
            return;

        let vehicles = JSON.parse(localStorage.getItem('newUserVehicles')).filter(e => e !== deletedVehicle)
        localStorage.setItem('newUserVehicles', JSON.stringify(vehicles))

        setVehicleList(vehicleList.filter(e => e.key !== deletedVehicle))

        vehicles = JSON.parse(localStorage.getItem('delUserVehicles'))
        localStorage.setItem('delUserVehicles', JSON.stringify(vehicles.concat(deletedVehicle)))

        $('#btnSaveChanges').prop('disabled', false)
    }, [isDeleted])

    useEffect(() => {
        setVehicleList(props.userVehicles.map(e => (<SwiperSlide key={e.name}> <VehicleCard details={e} deleteVehicleCallBack={deleteVehicleCallBack} /> </SwiperSlide>)));
    }, [props.userVehicles])

    useEffect(() => {
        if (vehicleList.length === 0) {
            $('.swiper-slide').addClass('vehicleCardMaxWidth');
        } else {
            $('.vehicleCardMaxWidth').removeClass('vehicleCardMaxWidth');
        }
    }, [vehicleList])

    if (vehicleList.length === 0) {
        return (<div className="card">
            <div id="AddVehicle" className="addVehicleCard">
                <img src="https://cdn-icons-png.flaticon.com/128/3097/3097180.png" alt="Car Icon Here"></img>
                <h3>Add your ride</h3>
                <p>Select your vehicle to get additional information about it</p>
                <div id="divFormContainer">
                    <div id="divAutocompleteContainer">
                        <ReactSearchAutocomplete
                            items={Object.keys(props.vehicleOptions).map((e, i) => {
                                return { id: i, name: props.vehicleOptions[i] }
                            })}
                            onSearch={handleSearch}
                            onSelect={handleOnSelect}
                            formatResult={(item) =>
                                <span>{item.name}</span>
                            }
                            showIcon={false}
                            maxResults={2}
                            placeholder="Eg: Tata Tigor EV"
                            styling={{
                                height: "33px",
                                padding: "0px",
                                backgroundColor: "white",
                                boxShadow: "none",
                                border: "none",
                                hoverBackgroundColor: "#eee",
                                color: "#808080",
                                fontSize: "12px",
                                fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                                lineColor: "rgb(232, 234, 237)",
                                placeholderColor: "grey",
                                clearIconMargin: '3px 14px 0 0',
                            }}
                        />
                    </div>
                    <button id='btnAddVehicle' className="settingsButton fa-solid fa-plus" onClick={submitForm}></button>
                </div>
            </div>
        </div>)
    }
    else {
        return (
            <Swiper
                scrollbar={{ hide: true }}
                freeMode={true}
                modules={[Scrollbar, FreeMode]}
                slidesPerView={vehicleList.length === 0 ? 1.06 : (window.innerWidth > 768 ? 2.4 : 'auto')}
                grabCursor={true}
                noSwipingSelector='input, select, option, button, ul, li'
                slidesOffsetBefore={10}
                slidesOffsetAfter={10}
                slidesPerGroup={1}
                spaceBetween={20}
                className="mySwiper"
            >
                {vehicleList}
                <SwiperSlide>
                    <div className="card">
                        <div id="AddVehicle" className="addVehicleCard">
                            <img src="https://cdn-icons-png.flaticon.com/128/3097/3097180.png" alt="Car Icon Here"></img>
                            <h3>Add your ride</h3>
                            <p>Select your vehicle to get additional information about it</p>
                            <div id="divFormContainer">
                                <div id="divAutocompleteContainer">
                                    <ReactSearchAutocomplete
                                        items={Object.keys(props.vehicleOptions).map((e, i) => {
                                            return { id: i, name: props.vehicleOptions[i] }
                                        })}
                                        onSearch={handleSearch}
                                        onSelect={handleOnSelect}
                                        formatResult={(item) =>
                                            <span>{item.name}</span>
                                        }
                                        showIcon={false}
                                        maxResults={2}
                                        placeholder="Eg: Tata Tigor EV"
                                        styling={{
                                            height: "33px",
                                            padding: "0px",
                                            backgroundColor: "white",
                                            boxShadow: "none",
                                            border: "none",
                                            hoverBackgroundColor: "#eee",
                                            color: "#808080",
                                            fontSize: "12px",
                                            fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                                            lineColor: "rgb(232, 234, 237)",
                                            placeholderColor: "grey",
                                            clearIconMargin: '3px 14px 0 0',
                                        }}
                                    />
                                </div>
                                <button id='btnAddVehicle' className="settingsButton fa-solid fa-plus" onClick={submitForm}></button>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        )
    }
}


export default VehicleCarousel