import { useEffect } from "react";
import $ from "jquery";

function Map(props){
    useEffect(() => {
        $('.accountDetailsModal').animate({ height: '0px' }, () => {
            $('.accountDetailsModal').hide();
            $('.accountDetailsContainer').hide().css('z-index', -1);
            $("#divAccountContentContainer").prop("onclick", null).off("click");
        });
    }, []);
    return(<></>);
}

export default Map;