import { ProtectedAPI } from './index';

export function getUser() {
    let uid = localStorage.getItem('userId');
    let url = '/user/get/'+ uid;
    let options = {}
    
    options.method = "GET";
    let resp = ProtectedAPI(url, options);
    return resp
}

export function getWallet() {
    let uid = localStorage.getItem('userId');
    let url = '/user/get/wallet/' + uid;
    let options = {}
    
    options.method = "GET";
    let resp = ProtectedAPI(url, options);
    return resp
}

export function deductWallet(sessionId) {
    let uid = localStorage.getItem('userId');
    let url =  '/user/deduct/wallet/' + uid;
    let options = {}
    options.method = "POST";
    options.body = {"sessionId": sessionId}
    let resp = ProtectedAPI(url, options)
    return resp
}

export function createOrder(amount, phoneNumber, name) {
    let uid = localStorage.getItem('userId');
    let url =  '/user/create/order/' + uid;
    let options = {}
    
    options.method = "POST";
    options.body = {"amount": amount, "phoneNumber": phoneNumber, name: name}
    let resp = ProtectedAPI(url, options)
    return resp
}

export function checkOrder(checkHeaders, checkUrl) {
    let uid = localStorage.getItem('userId');
    let url =  '/user/check/order/' + uid;
    let options = {}
    options.body = {checkHeaders: checkHeaders, checkUrl: checkUrl}
    options.method = "POST"
    let resp = ProtectedAPI(url, options)
    return resp
}

export function getUserData(){
    let uid = localStorage.getItem('userId');
    let url = '/user/get/allVehicles/' + uid;
    let options = {}
    options.method = "GET"
    let resp = ProtectedAPI(url, options)
    return resp
}

export function changeUserData(obj){
    let uid = localStorage.getItem('userId');
    let url = '/user/updateUser/' + uid;
    let options = {}
    options.method = "POST"
    options.body = obj
    let resp = ProtectedAPI(url, options)
    return resp
}

export function getVehicle(vehicleName){
    let uid = localStorage.getItem('userId');
    let url = '/user/getVehicle/' + uid;
    let options = {}
    options.method = "POST"
    options.body = vehicleName
    let resp = ProtectedAPI(url, options)
    return resp
}

export function removeUserActiveSession(){
    let uid = localStorage.getItem('userId');
    let url = '/user/removeActiveSession';
    let options = {}
    options.method = "POST"
    options.body = {
        "userId": uid,
    }
    ProtectedAPI(url, options)
}