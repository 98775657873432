import {apiURL} from './index'

export async function signin(formdata) {
    let url = apiURL() + '/auth/signin';
    let resp = fetch(url, {
        mode: 'cors',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: "application/json",
        },
        body: JSON.stringify(formdata),
    }).then(res => res.json()).then((resp) => {return resp}).catch((err) => {return err});
    return resp
}

export async function signup(formdata) {
    let url = apiURL() + '/auth/signup';
    let resp = fetch(url, {
        mode: 'cors',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: "application/json",
        },
        body: JSON.stringify(formdata),
    }).then(res => res.json()).then((resp) => {return resp}).catch((err) => {return err});
    return resp
}

export async function verifyOTP(formdata) {
    let url = apiURL() + '/auth/verify/otp';
    let resp = fetch(url, {
        mode: 'cors',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: "application/json",
        },
        body: JSON.stringify(formdata),
    }).then(res => res.json()).then((resp) => {return resp}).catch((err) => {return err});
    return resp
}

export function authenticateAccount(obj, next) {
    let expirationTime = new Date();
    expirationTime.setHours( expirationTime.getHours() + 2 );
    if (typeof window !== 'undefined') {
        localStorage.setItem('authToken', JSON.stringify(obj));
        localStorage.setItem('tokenExpiration', expirationTime.getTime());
        next();
    }
};

export function IsAuthenticatedAccount() {
    if (typeof window == 'undefined') {
        return false;
    }
    if (localStorage.getItem('authToken')) {
        let currentTime = new Date().getTime();
        let expirationTime = Math.floor(localStorage.getItem('tokenExpiration'))
        if(expirationTime < currentTime) {
            return false;
        }
        else {
            return JSON.parse(localStorage.getItem('authToken'));
        }
    } 
    else {
        return false;
    }
};