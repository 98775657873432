import { useEffect, useState } from 'react';
import { useLocation, useMatches, useNavigate } from 'react-router-dom';

import $ from 'jquery';
import spotlight_logo_icon from '../assets/Spotlight_Logo_Icon.svg';

function NavHeader() {
    let navigate = useNavigate();
    let location = useLocation();
    let matches = useMatches();
    const [menuState, setMenuState] = useState(false);
    const [navRoute, setNavRoute] = useState('map');
    let handleIconToggle = () => {
        setMenuState(!menuState);
        navigate(matches[1].pathname + '/map');
    }
    let handleNavigation = (navItem) => {
        setMenuState(false);
        setNavRoute(navItem)
        navigate(matches[1].pathname + '/' + navItem);
        $('.navButtonContainer i').removeClass('active')
        $('#' + navItem + 'Nav').addClass('active');
        if (navItem === 'map') {
            console.log(navRoute);
            $('.accountDetailsModal').animate({ height: '0px' }, () => {
                $('.accountDetailsModal').hide();
                $('.accountDetailsContainer').hide().css('z-index', -1);
                $("#divAccountContentContainer").prop("onclick", null).off("click");
            });
        }
        else if (navItem === 'wallet') {
            var nextHeight = $(window).height() * 0.93 - 95;
            $('.accountDetailsContainer').css({'display': 'flex', 'z-index': 998});
            $('.accountDetailsModal').css('display', 'flex');
            $('.accountDetailsModal').animate({ height: nextHeight, maxHeight: "100%" });
        }
        else if(navItem === 'settings') {
            var nextHeight = $(window).height() * 0.93 - 95;
            $('.accountDetailsContainer').css({'display': 'flex', 'z-index': 998});
            $('.accountDetailsModal').css('display', 'flex');
            $('.accountDetailsModal').animate({ maxHeight: 535, height: "auto" });
        }
        else if(navItem === 'contactUs') {
            var nextHeight = $(window).height() * 0.93 - 95;
            $('.accountDetailsContainer').css({'display': 'flex', 'z-index': 998});
            $('.accountDetailsModal').css('display', 'flex');
            $('.accountDetailsModal').animate($(window).width() > 430? { maxHeight: 350,height: nextHeight }:{ maxHeight: 350,height: nextHeight });
        }
    }
    useEffect(() => {
        if (menuState) {
            $('.headerNav i').toggleClass('fa-bars fa-x');
            $('.accountNav').show();
            var nextHeight = $(window).height() - 80;
            $('.accountNav').animate({ height: nextHeight });
            $('.accountDetailsModal').animate({ height: '0px' }, () => {
                $('.accountDetailsModal').hide();
                $('.accountDetailsContainer').hide().css('z-index', -1);
            });
        }
        else {
            $('.headerNav i').removeClass('fa-bars fa-x').addClass('fa-bars');
            $('.accountNav').animate({ height: "0px" }, () => {
                $('.accountNav').hide();
            })
        }
    }, [menuState])
    return (
        <div id="divNavContainer" className='navContainer'>
            <div className='headerNav'>
                <img className='logo' src={spotlight_logo_icon} alt="" />
                <i className="fa-solid fa-bars" onClick={() => { handleIconToggle(); }}></i>
            </div>
            <div className='accountNav'>
                <h1 id="mapNav" onClick={() => { handleNavigation('map')}}>Map</h1>
                <h1 id="walletNav" onClick={() => { handleNavigation('wallet')}}>Wallet</h1>
                <h1 id="settingsNav" onClick={() => { handleNavigation('settings')}}>Settings</h1>
                <h1 id="settingsNav" onClick={() => { handleNavigation('contactUs')}}>Contact Us</h1>
            </div>
        </div>
    )
}

export default NavHeader