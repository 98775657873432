export function apiURL() {
    if (process.env.NODE_ENV === 'development') {
        return process.env.REACT_APP_SERVER_API_URL;
    }
    else {
        return '/api'
    }
}

export function getAPI(url, options) {
    url = apiURL() + url;
    let resp = fetch(url, {
        mode: 'cors',
        method: 'GET',
        headers: {
            Authorization: localStorage.getItem('authorization'),
        }
    }).then(res => res.json())
    .then((resp) => {
        if(resp.error){
            localStorage.clear();
            window.location.replace('/');
        }
        return resp 
    });
    return resp
}

export function postAPI(url, options) {
    return new Promise((resolve, reject) => {
        url = apiURL() + url;
        fetch(url, {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
            },
            body: JSON.stringify(options.body),
        }).then(res => res.json()).then((resp) => { resolve(resp) });
    })
}

export async function ProtectedAPI(url, options={}) {
    if(((Date.now() / 1000) | 0)>=localStorage.getItem('exp')){
        localStorage.clear();
        window.location.replace('/');
    }
    let headers = {
        'Content-Type': 'application/json',
        Accept: "application/json",
        Authorization: localStorage.getItem('authorization'),
    };
    url = apiURL() + url;
    if(options.headers){
        headers = { ...headers, ...options.headers}
    }
    if(options.body)
        options.body = JSON.stringify(options.body)
    let resp = fetch(url, {
        mode: 'cors',
        method: options.method,
        headers: headers,
        body: options.body,
    }).then(res => res.json()).then((resp) => {
        if(resp.status === 401 || resp.status === 404){
            localStorage.clear();
            window.location.replace('/');
        } else if(resp.status === 205){
            window.location.replace('/');
        }
        return resp
    }).catch(err => {
        console.log(err);
    });
    return resp
}

export function postProtectedAPI(url, options, ingressToken) {
    url = apiURL() + url;
    let resp = fetch(url, {
        mode: 'cors',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: "application/json",
            Authorization: ingressToken
        },

        body: JSON.stringify(options.body),
    }).then(res => res.json()).then((resp) => {
        return resp
    }).catch(err => {
        console.log(err);
    });
    return resp
}