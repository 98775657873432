import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect } from 'react';
import { useRef, forwardRef, useImperativeHandle } from 'react'

const RadialChart = forwardRef((props, ref) => {
    let chartComponent = useRef();
    useImperativeHandle(
        ref,
        () => ({
            resizeChart() {
                chartComponent.current.chart.reflow();
            }
         }),
     )
    const options = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: 0,
            plotShadow: false,
            backgroundColor: '#93a1b2',
        },
        title: {
            text: 'Usage Breakdown by Location',
            align: 'center',
            style: {"font-size": "14px", "font-weight": 'bold'}
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    enabled: false,
                    distance: -50,
                    style: {
                        fontWeight: 'bold',
                        color: 'white'
                    }
                },
                startAngle: -180,
                endAngle: 180,
                center: ['50%', '50%'],
                size: '100%',
                borderColor: '#ffffff00'
            }
        },
        legend: {
            enbled: true,
            labelFormat: '{name}: {y} kwH',
            layout: 'vertical',
        },
        credits: {
            enabled: false
        },
        series: [{
            type: 'pie',
            name: 'Usage Breakdown',
            innerSize: '90%',
            showInLegend: true,
            data: [
                {
                    name: 'Home',
                    y: 58.9,
                    color: "#0058FF"
                },
                {
                    name: 'Office',
                    y: 13.29,
                    color: "#21D59B"
                },
                {
                    name: 'Restaraunts',
                    y: 13,
                    color: "#FFC700"
                },
                {
                    name: 'Public',
                    y: 3.78,
                    color: "#F99600"
                },
                {
                    name: 'Other',
                    y: 7.61,
                    dataLabels: {
                        enabled: false
                    }
                }
            ]
        }]
    };
    useEffect(() => {
        ref.current.resizeChart();
    }, [])
    return (
        <div id="radialChart" className="chartContainer" style={props.containerProps? {} : {marginLeft: "39px"}}>
            <HighchartsReact id="chart" highcharts={Highcharts} ref={chartComponent} options={props.options? props.options : options} containerProps={props.containerProps? props.containerProps : { className: 'chart', style: { height: "100%" } }} />
        </div>
    )
});
export default RadialChart