import { useEffect, useRef, useState } from "react";

const Stopwatch = (props) => {
    const [time, setTime] = useState(0);
    let interval = useRef();
    let startTime = useRef();
    let onHiddenTime = useRef();

    function handleVisibilityChanges() {
        if (document.visibilityState === "hidden") {
            onHiddenTime.current = Date.now()
        }
        else {
            setTime((prevTime) => prevTime + (Date.now() - onHiddenTime.current))
        }
    }

    function handleIsRunning(running) {
        clearInterval(interval.current);
        if (running) {
            interval.current = setInterval(() => {
                setTime(time => {
                    if (time > 0) {
                        return time + ((props.direction < 0 ? -1 : 1) * 10)
                    } else {
                        handleIsRunning(false);
                        return 0;
                    }
                })
            }, 10);
        }
    }

    useEffect(() => {
        if (props.command === 'reset') {
            setTime(props.startTime || 0);
            handleIsRunning(false);
        } else if (props.command === 'start') {
            startTime.current = Date.now() - props.startTime;
            handleIsRunning(true)
        } else {
            handleIsRunning(false)
        }
    }, [props.command])
    useEffect(() => {
        setTime(props.startTime)
        startTime.current = Date.now() - props.startTime;
        handleIsRunning(true)
    }, [props.startTime])
    useEffect(() => {
        document.addEventListener("visibilitychange", handleVisibilityChanges)
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChanges)
        }
    }, [])
    return (
        <div className="stopwatch">
            <div className="numbers">
                <span>{("0" + Math.floor((Math.abs(time) / 3600000) % 60)).slice(-2)}:</span>
                <span>{("0" + Math.floor((Math.abs(time) / 60000) % 60)).slice(-2)}:</span>
                <span>{("0" + Math.floor((Math.abs(time) / 1000) % 60)).slice(-2)}</span>
            </div>
        </div>
    );
};

export default Stopwatch