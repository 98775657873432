import './Oops.scss';
import spotlight_logo from '../../assets/Spotlight_Logo.svg'
import wavefuel_logo from '../../assets/powered_by_wavefuel.svg'

function Oops() {
    return (
        <div className='parentErrorContainer justifyAround'>
            <img className='logo' src={spotlight_logo} alt="" />
            <div>
                <h1 className="message__title">Page Not Found</h1>
                <p className="message__text">We're sorry, the page you were looking for isn't found here. The link you followed may either be broken or no longer exists. Please try again, or take a look at our.</p>
            </div>
            <img className='parentLogo' src={wavefuel_logo} alt="" />
        </div>
    )
}

export default Oops;