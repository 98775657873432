import '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import * as turf from '@turf/turf'

import { useEffect, useRef, useState } from 'react'

import $ from 'jquery';
import DeviceModal from '../../components/DeviceModal/DeviceModal';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { ProtectedAPI } from '../../api';
import mapboxgl from 'mapbox-gl';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
// Change this to set the app to your location 
// This value is used for the map center, the search proximity bias, and the store location
function GeoMap(props) {
    const [currentLocation, setCurrentLocation] = useState([]);
    const [mapState, setMapState] = useState(null);
    const [activeDevice, setActiveDevice] = useState(props.activeChargePoint !== null ?
        {
            id: props.activeChargePoint.device._id,
            assetId: props.activeChargePoint.asset._id,
            accessCategory: props.activeChargePoint.asset.accessCategory,
            coordinates: [props.activeChargePoint.device.location.lon || 0, props.activeChargePoint.device.location.lat || 0],
            chargeStatus: props.activeChargePoint.asset.connectors[props.activeChargePoint.connectorId - 1].chargingStatus.toLowerCase() || 'unavailable',
            name: props.activeChargePoint.device.deviceName,
            location: props.activeChargePoint.device.address || null,
            outputType: props.activeChargePoint.asset.outputType || null,
            capacity: props.activeChargePoint.asset.outputWattCategory || null,
            pricingPolicy: props.activeChargePoint.asset.pricingPolicy || null,
            connectorType: props.activeChargePoint.asset.deviceConnectorType || null,
            directions: props.activeChargePoint.device.directionsDescription || null,
            projectName: props.activeChargePoint.project?.projectName || null,
            project: props.activeChargePoint.project,
            connectors: props.activeChargePoint.asset.connectors,
            connectorId: props.activeChargePoint.connectorId,
            reservedTime: props.activeChargePoint.asset.connectors[props.activeChargePoint.connectorId - 1]?.reservedTime
        }
        : {
            id: null,
            assetId: null,
            coordinates: [0, 0],
            chargeStatus: null,
            name: '',
            location: '',
            outputType: null,
            capacity: null,
            pricingPolicy: null,
            connectorType: null,
            directions: null,
            projectName: null,
            project: null,
            connectors: [],
            connectorId: null,
            reservedTime: null
        });
    const reservedUser = useRef(props.activeChargePoint !== null ? props.activeChargePoint.asset.connectors[props.activeChargePoint.connectorId - 1]?.reservedUser : null)
    const [stations, setStations] = useState(null);
    const [projects, setProjects] = useState(props.projects);
    mapboxgl.accessToken = process.env.REACT_APP_MAP_API_KEY;
    let mapContainer = useRef(null)
    let mapGl = useRef(null);
    let boundsGl = useRef(null);
    let renderDevices = (projects) => {
        if (projects) {
            let devicesList = [], inv = [];
            let index = 1;
            for (let project of projects) {
                project.devices = project.devices.filter(e => e !== null && e.assetId.connectors.length > 0 && e.deviceStatus === 'Active');
                devicesList = [...devicesList, ...(project.devices.map((e) => {
                    return {
                        id: index++, // Hotfix
                        type: 'Feature',
                        properties: {
                            chargeStatus: e.assetId.statusNotification.toLowerCase(),
                            id: e._id.toString(),
                            device: e,
                            project: project,
                        },
                        geometry: {
                            type: 'Point',
                            coordinates: [e.location.lon || 78, e.location.lat || 26]
                        }
                    }
                }))]
            }
            return { features: devicesList };
        }
    }
    const transformRequest = (url) => {
        const hasQuery = url.indexOf("?") !== -1;
        const suffix = hasQuery ? "&pluginName=lunchboxOptimization" : "";
        return {
            url: url + suffix
        }
    }
    const setTripLine = function (trip) {
        const routeLine = {
            type: 'FeatureCollection',
            features: [{
                properties: {},
                geometry: trip.geometry,
            }],
        };

        mapGl.current.getSource('route').setData(routeLine);
    }
    const getDirectionsRoute = async function (coords) {
        let tripCoords = [currentLocation.join(','), coords.join(',')]
        let optimizeUrl = 'https://api.mapbox.com/directions/v5/';
        optimizeUrl += 'mapbox/driving-traffic/';
        optimizeUrl += tripCoords.join(';');
        optimizeUrl += '?access_token=' + mapboxgl.accessToken;
        optimizeUrl += '&geometries=geojson&steps=true';

        let resp = await fetch(optimizeUrl).then((res) => res.json()).then((res) => {
            setTripLine(res.routes[0]);
            return res.routes[0].legs[0].steps;
        });
        return resp
    };
    const checkAddressInServiceArea = async function (address) {
        if (address) {
            let a = await getDirectionsRoute(address.geometry.coordinates);
            return a;
        }
        return null;
    };
    const getIso = function (coords) {
        if (mapGl.current !== null) {
            let isoUrl = 'https://api.mapbox.com/isochrone/v1/mapbox/driving/' + coords.join(',') + '.json';
            isoUrl += '?contours_minutes=30&polygons=true&access_token=' + mapboxgl.accessToken;
            fetch(isoUrl).then(res => res.json()).then(res => {
                return res;
            });
        }
    };
    const getCurrentLocation = async (updateSource) => {
        return new Promise((resolve, reject) => {
            try {
                if (navigator.geolocation) {
                    let currentLoc = navigator.geolocation.getCurrentPosition(function (position) {
                        setCurrentLocation([position.coords.longitude, position.coords.latitude])
                        resolve([position.coords.longitude, position.coords.latitude])
                    }, (err) => {
                        console.log(err);
                        resolve([]);
                    }, { timeout: 10000, enableHighAccuracy: true });
                }
            }
            catch (err) {
                if (updateSource) clearInterval(updateSource);
                resolve([]);
            }

        });
    };
    async function getReverseGeocode(lng, lat) {
        if (!(lng && lat))
            return null;
        var url = "https://api.mapbox.com/geocoding/v5/mapbox.places/" + lng + "%2C" + lat + ".json?access_token=" + mapboxgl.accessToken;
        let resp = await fetch(url)
            .then(res => res.json())
            .then(res => {
                let a = checkAddressInServiceArea(res.features[0], mapGl.current);
                return a;
            });
        let x2 = 999, y2 = 999, x1 = 0, y1 = 0;
        let centerY = 999;
        for (let i in resp) {
            if (resp[i]['geometry']['coordinates'][0][1] < centerY)
                centerY = resp[i]['geometry']['coordinates'][0][1];
            if (resp[i]['geometry']['coordinates'][0][0] > x1)
                x1 = resp[i]['geometry']['coordinates'][0][0];
            if (resp[i]['geometry']['coordinates'][0][1] > y1)
                y1 = resp[i]['geometry']['coordinates'][0][1];
            if (resp[i]['geometry']['coordinates'][0][0] < x2)
                x2 = resp[i]['geometry']['coordinates'][0][0];
            if (resp[i]['geometry']['coordinates'][0][1] < y2)
                y2 = resp[i]['geometry']['coordinates'][0][1];
        }
        let dist = y1 - y2;
        let centerX = (x1 + x2) / 2;
        mapGl.current.fitBounds([
            [x1, y1 + dist],
            [x2, y2 - dist]
        ], { center: [centerX, centerY - (3 * dist * 8 / 100)], padding: 50 });
        return resp;
    }
    function renderUserLocationDot(size, map) {
        const pulsingDot = {
            width: size,
            height: size,
            data: new Uint8Array(size * size * 4),

            onAdd: function () {
                const canvas = document.createElement('canvas');
                canvas.width = this.width;
                canvas.height = this.height;
                this.context = canvas.getContext('2d');
            },

            render: function () {
                const duration = 1000;
                const t = (performance.now() % duration) / duration;

                const radius = (size / 2) * 0.3;
                const outerRadius = (size / 2) * 0.7 * t + radius;
                const context = this.context;

                context.clearRect(0, 0, this.width, this.height);
                context.beginPath();
                context.arc(
                    this.width / 2,
                    this.height / 2,
                    outerRadius,
                    0,
                    Math.PI * 2
                );
                context.fillStyle = `rgba(255, 200, 200, ${1 - t})`;
                context.fill();

                context.beginPath();
                context.arc(
                    this.width / 2,
                    this.height / 2,
                    radius,
                    0,
                    Math.PI * 2
                );
                context.fillStyle = 'rgba(255, 100, 100, 1)';
                context.strokeStyle = 'white';
                context.lineWidth = 2 + 4 * (1 - t);
                context.fill();
                context.stroke();

                this.data = context.getImageData(
                    0,
                    0,
                    this.width,
                    this.height
                ).data;

                map.triggerRepaint();

                return true;
            }
        };
        return pulsingDot;
    }
    function addLiveLocationTracker(map) {
        let pulsingDot = renderUserLocationDot(80, map);
        map.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2, sdf: false });
        map.addSource("liveLocation", {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: [
                ]
            }
        });
        map.addLayer({
            "id": "liveLocationLayer",
            "type": "symbol",
            "source": "liveLocation",
            "layout": {
                'icon-image': 'pulsing-dot',
                "icon-allow-overlap": true,
                "visibility": "visible"
            },
            "paint": {
            }
        }, "road-label-navigation");

    }
    function addChargingStations(map) {

        map.addSource("deviceSource", {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: [
                ]
            },
            cluster: true,
            clusterMaxZoom: 15, // Max zoom to cluster points on
            clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
        });

        map.addLayer({
            "id": "deviceLayer",
            "type": "symbol",
            "source": "deviceSource",
            "filter": ['!', ['has', 'point_count']],
            "layout": {
                'icon-image': [
                    'match',
                    ['get', 'chargeStatus'],
                    'unavailable', 'chargepoint-unavailable',
                    'inactive', 'chargepoint-inactive',
                    'faulted', 'chargepoint-inactive',
                    'available', 'chargepoint-active',
                    'charging', 'chargepoint-inuse',
                    'preparing', 'chargepoint-inuse',
                    'finishing', 'chargepoint-inuse',
                    'suspendedevse', 'chargepoint-inuse',
                    'suspendedev', 'chargepoint-inuse',
                    'chargepoint-unavailable'
                ],
                "icon-allow-overlap": true,
                "icon-size": 0.5,
                "visibility": "none",
                "symbol-sort-key": 3,
                "symbol-z-order": 'auto',
            },
            "paint": {
                "icon-color": '#345e3f',
            }
        }, "road-label-navigation");


        map.addLayer({
            id: 'deviceClusters',
            type: 'circle',
            source: 'deviceSource',
            filter: ['has', 'point_count'], // Only display clusters
            paint: {
                'circle-color': [
                    'step',
                    ['get', 'point_count'],
                    'rgba(255, 192, 0, 1)',
                    3,
                    'rgba(255, 128, 0, 1)',
                    10,
                    'rgba(255, 64, 0, 1)',
                    60,
                    'rgba(255, 0, 0, 1)',
                ],
                'circle-radius': [
                    'step',
                    ['get', 'point_count'],
                    15,
                    3,
                    20,
                    6,
                    25
                ],
                "circle-opacity": 0.5,
            },
            layout: {
                "visibility": "none",
            }
        });

        map.addLayer({
            id: 'device-count',
            type: 'symbol',
            source: 'deviceSource',
            filter: ['has', 'point_count'],
            layout: {
                'text-field': ['get', 'point_count_abbreviated'],
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 12,
                "visibility": "none",
            }
        });
    }
    function addDirectionsSources_Layers(map) {
        map.addSource('route', {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: [
                ],
            },
        });

        map.addLayer({
            id: 'routeLayer',
            type: 'line',
            source: 'route',
            layout: {},
            paint: {
                'line-color': '#cc5353',
                'line-width': 6,
                "line-opacity": 0.8,
            },
        }, 'road-label-navigation');

        map.addLayer({
            id: 'routeArrows',
            source: 'route',
            type: 'symbol',
            layout: {
                'symbol-placement': 'line',
                'text-field': '→',
                'text-rotate': 0,
                'text-keep-upright': false,
                'symbol-spacing': 30,
                'text-size': 15,
                'text-offset': [0, -0.1],
            },
            paint: {
                'text-color': 'white',
                'text-halo-color': 'white',
                'text-halo-width': 1,
            },
        }, 'road-label-navigation');
    }
    function handleFeatureClick(map, selectedFeatures) {
        if (reservedUser.current !== localStorage.getItem("userId")) {
            if (selectedFeatures.length > 0) {
                if (selectedFeatures[0].properties.device) {
                    if ((typeof selectedFeatures[0].properties.device) === "string")
                        selectedFeatures[0].properties.device = JSON.parse(selectedFeatures[0].properties.device);
                    map.setLayoutProperty('deviceLayer', 'icon-image',
                        [
                            'match',
                            ['get', 'chargeStatus'],
                            'unavailable',
                            [
                                'match',
                                ['id'],
                                selectedFeatures[0].id,
                                'chargepoint-selected',
                                'chargepoint-unavailable'
                            ],
                            'inactive',
                            [
                                'match',
                                ['id'],
                                selectedFeatures[0].id,
                                'chargepoint-selected',
                                'chargepoint-inactive'
                            ],
                            'faulted',
                            [
                                'match',
                                ['id'],
                                selectedFeatures[0].id,
                                'chargepoint-selected',
                                'chargepoint-inactive'
                            ],
                            'available',
                            [
                                'match',
                                ['id'],
                                selectedFeatures[0].id,
                                'chargepoint-selected',
                                'chargepoint-active'
                            ],
                            'charging',
                            [
                                'match',
                                ['id'],
                                selectedFeatures[0].id,
                                'chargepoint-selected',
                                'chargepoint-inuse'
                            ],
                            'preparing',
                            [
                                'match',
                                ['id'],
                                selectedFeatures[0].id,
                                'chargepoint-selected',
                                'chargepoint-inuse'
                            ],
                            'finishing',
                            [
                                'match',
                                ['id'],
                                selectedFeatures[0].id,
                                'chargepoint-selected',
                                'chargepoint-inuse'
                            ],
                            'suspendedevse',
                            [
                                'match',
                                ['id'],
                                selectedFeatures[0].id,
                                'chargepoint-selected',
                                'chargepoint-inuse'
                            ],
                            'suspendedev',
                            [
                                'match',
                                ['id'],
                                selectedFeatures[0].id,
                                'chargepoint-selected',
                                'chargepoint-inuse'
                            ],
                            'chargepoint-unavailable'
                        ]
                    )
                    const coordinates = [selectedFeatures[0].properties.device.location?.lon || 0, selectedFeatures[0].properties.device.location?.lat || 0];
                    let project = selectedFeatures[0].properties.project
                    if ((typeof selectedFeatures[0].properties.project) === "string")
                        project = JSON.parse(selectedFeatures[0].properties.project);
                    project.devices = (project.devices.filter(e => e.assetId.connectors.length > 0)).sort((a, b) => {
                        return (b.assetId.statusNotification === 'Available') - (a.assetId.statusNotification === 'Available');
                    })
                    let connectorId = 1;
                    selectedFeatures[0].properties.device.assetId.connectors.find((e, i) => {
                        if (e.chargingStatus === 'Available') {
                            connectorId = e.connectorNumber;
                            return true;
                        }
                    })
                    setActiveDevice((device) => {
                        return {
                            ...device,
                            id: selectedFeatures[0].properties.device._id,
                            assetId: selectedFeatures[0].properties.device.assetId._id,
                            accessCategory: selectedFeatures[0].properties.device.assetId.accessCategory,
                            coordinates: coordinates,
                            chargeStatus: selectedFeatures[0].properties.device.assetId.connectors[connectorId - 1].chargingStatus.toLowerCase() || null,
                            name: selectedFeatures[0].properties.device.deviceName || null,
                            outputType: selectedFeatures[0].properties.device.assetId.outputType || null,
                            capacity: selectedFeatures[0].properties.device.assetId.outputWattCategory || null,
                            pricingPolicy: selectedFeatures[0].properties.device.assetId.pricingPolicy || null,
                            connectorType: selectedFeatures[0].properties.device.assetId.connectors[connectorId - 1].connectorType || null,
                            directions: '' || null,
                            connectors: selectedFeatures[0].properties.device.assetId.connectors,
                            projectName: project.projectName || null,
                            project: project,
                            location: project.address || null,
                            connectorId: connectorId,
                            reservedTime: selectedFeatures[0].properties.device.assetId.connectors[connectorId - 1]?.reservedTime
                        }
                    })
                    reservedUser.current = selectedFeatures[0].properties.device.assetId.connectors[connectorId - 1]?.reservedUser
                }
            }
            else {
                $('.projectModalContainer').animate({ bottom: "10px", opacity: 0 }, () => {
                    $('.projectModalContainer').hide();
                    $('.projectModalContainer').css({ zIndex: -999 });
                    $('.reserveInputs').hide();
                    $('.deviceButtons').show();
                    $('.cancelButton').show();
                    $('.expandButton').hide();
                    $('.deviceMetrics').fadeIn();
                    $('#modalStationDetails').hide();
                    $('#modalStationDetails').css({ zIndex: -999 });
                    $('.endSessionButton').hide();
                    $('.transactionButton').html('Start Session').removeClass('active preparing disabled').animate({ width: "100%" })
                    $('.stationInvoiceContainer').fadeOut();
                    $('.energyOutput>span').html(0);
                    setActiveDevice({
                        id: null,
                        assetId: null,
                        accessCategory: null,
                        coordinates: [0, 0],
                        chargeStatus: null,
                        name: '',
                        location: '',
                        outputType: null,
                        capacity: null,
                        pricingPolicy: null,
                        connectorType: null,
                        directions: null,
                        project: null,
                        connectors: [],
                        connectorId: null,
                        reservedTime: null
                    })
                })
            }
        }
    }
    function initializeMap(mapContainer) {
        const map = new mapboxgl.Map({
            transformRequest: transformRequest,
            container: mapContainer,
            style: 'mapbox://styles/mrpurple/ckkir186f14lx17nx1s1qk47z?optimize=true',
            maxPitch: 70,
            center: [78.42, 20.67],
            zoom: 4
        });
        const geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            flyTo: true,
            marker: true,
            proximity: currentLocation,
        });
        map.on("load", () => {
            addChargingStations(map);
            addDirectionsSources_Layers(map)
            geocoder.on("result", ev => {
                map.fire('click', { lngLat: { lng: ev.result.geometry.coordinates[0], lat: ev.result.geometry.coordinates[1] } })
            });
            addLiveLocationTracker(map);
            getCurrentLocation()
                .then(res => {
                    map.once('moveend', function () {
                        map.setMinZoom(4);
                    });
                    setActiveDevice((device) => { // Hotfix
                        if (!props.activeChargePoint && (device.name === '' && device.location === '') && boundsGl.current?._ne && boundsGl.current?._sw) mapGl.current.fitBounds(boundsGl.current, { padding: 50 });
                        return device;
                    })
                    navigator.permissions.query({ name: 'geolocation' }).then(resp => {
                        if (resp.state !== 'denied' && res.length === 2) {
                            let initLocation = {
                                type: "FeatureCollection",
                                features: [{
                                    type: 'Feature',
                                    geometry: {
                                        type: 'Point',
                                        coordinates: res
                                    }
                                }]
                            }
                            boundsGl.current.extend(res);
                            map.getSource('liveLocation').setData(initLocation)
                        }
                    }).catch(function (error) {
                        console.log('Error checking location permission:', error);
                    });
                    setMapState(map);
                    $('#geoSearch').append(geocoder.onAdd(mapState));
                    map.on('click', (e) => {
                        if (e.originalEvent.detail > 1)
                            return;
                        if (props.activeChargePoint && props.state) props.state.activeDevice = false;
                        const selectedFeatures = map.queryRenderedFeatures(e.point, {
                            layers: ['deviceLayer', 'deviceClusters'],
                        });
                        let closest = [];
                        let distance = 999999;
                        for (let i in selectedFeatures) {
                            let dis = e.lngLat.distanceTo({ lng: selectedFeatures[i].geometry.coordinates[0], lat: selectedFeatures[i].geometry.coordinates[1] })
                            if (dis < distance) {
                                distance = dis;
                                closest = [selectedFeatures[i]]
                            }
                        }
                        if (!closest[0]?.layer.id.includes('Clusters')) {
                            let routeData = {
                                type: 'FeatureCollection',
                                features: [
                                ],
                            }
                            if (!localStorage.getItem('activeSession')) {
                                if ($('#divDirections').filter(":visible").length !== 0 && closest.length !== 0)
                                    $('.directionsBackButton > i')[0].click();
                                handleFeatureClick(map, closest);
                            }
                            if ($('#divDirections').filter(":visible").length === 0) {
                                map.getSource('route').setData(routeData);
                            }
                        }
                        else {
                            let clusterSource = map.getSource(closest[0]?.layer.source);
                            clusterSource.getClusterLeaves(closest[0].properties.cluster_id, closest[0].properties.point_count, 0, function (err, aFeatures) {
                                console.log('getClusterLeaves', err, aFeatures);
                                let bounds = new mapboxgl.LngLatBounds();
                                let lon = 0, lat = 0;
                                for (let feature of aFeatures) {
                                    bounds.extend(feature.geometry.coordinates);
                                    lon += feature.geometry.coordinates[0];
                                    lat += feature.geometry.coordinates[1];
                                }
                                lon /= aFeatures.length;
                                lat /= aFeatures.length;
                                map.fitBounds(bounds, { center: [lon, lat - 0.002], padding: 100 })
                            })
                        }
                    });
                    map.on('mouseenter', 'deviceLayer', () => {
                        map.getCanvas().style.cursor = 'pointer';
                    });
                    map.on('mouseleave', 'deviceLayer', () => {
                        map.getCanvas().style.cursor = '';
                    });
                    map.on('zoomend', (e) => {
                        try {
                            map.resize();
                        } catch (error) {
                            console.log(error);
                        }
                    })
                })
                .then(() => {
                    const updateSource = setInterval(async () => {
                        navigator.permissions.query({ name: 'geolocation' }).then(async resp => {
                            $('.directionsButton').prop('disabled', resp.state !== 'granted')
                            if (resp.state !== 'denied') {
                                let geojson = await getCurrentLocation(updateSource);
                                let updatedLocation = {
                                    type: "FeatureCollection",
                                    features: [{
                                        type: 'Feature',
                                        geometry: {
                                            type: 'Point',
                                            coordinates: geojson
                                        }
                                    }
                                    ]
                                }
                                map.getSource('liveLocation').setData(updatedLocation);
                            }
                            else {
                                clearInterval(updateSource);
                            }
                        }).catch(function (error) {
                            console.log('Error checking location permission:', error);
                        });
                    }, 60000);
                })
        });
        return { map: map, geocoder: geocoder }
    }

    function switchStation(change, e) {
        if ((e.detail - 1) % 3 !== 0) {
            return;
        }
        let index = activeDevice.project.devices.findIndex(device => device._id === activeDevice.id)
        if (index === 0)
            index = activeDevice.project.devices.length;
        index = (index + change) % activeDevice.project.devices.length;
        let deviceIndex = (mapGl.current.getSource('deviceSource')._data.features.findIndex(e => e.properties.id === activeDevice.project.devices[index]._id.toString())) + 1;

        mapGl.current.setLayoutProperty('deviceLayer', 'icon-image',
            [
                'match',
                ['get', 'chargeStatus'],
                'unavailable',
                [
                    'match',
                    ['id'],
                    deviceIndex,
                    'chargepoint-selected',
                    'chargepoint-unavailable'
                ],
                'inactive',
                [
                    'match',
                    ['id'],
                    deviceIndex,
                    'chargepoint-selected',
                    'chargepoint-inactive'
                ],
                'faulted',
                [
                    'match',
                    ['id'],
                    deviceIndex,
                    'chargepoint-selected',
                    'chargepoint-inactive'
                ],
                'available',
                [
                    'match',
                    ['id'],
                    deviceIndex,
                    'chargepoint-selected',
                    'chargepoint-active'
                ],
                'charging',
                [
                    'match',
                    ['id'],
                    deviceIndex,
                    'chargepoint-selected',
                    'chargepoint-inuse'
                ],
                'preparing',
                [
                    'match',
                    ['id'],
                    deviceIndex,
                    'chargepoint-selected',
                    'chargepoint-inuse'
                ],
                'finishing',
                [
                    'match',
                    ['id'],
                    deviceIndex,
                    'chargepoint-selected',
                    'chargepoint-inuse'
                ],
                'suspendedevse',
                [
                    'match',
                    ['id'],
                    deviceIndex,
                    'chargepoint-selected',
                    'chargepoint-inuse'
                ],
                'suspendedev',
                [
                    'match',
                    ['id'],
                    deviceIndex,
                    'chargepoint-selected',
                    'chargepoint-inuse'
                ],
                'chargepoint-unavailable'
            ]
        )
        let connectorId = 1;
        activeDevice.project.devices[index].assetId.connectors.find((e, i) => {
            if (e.chargingStatus === 'Available') {
                connectorId = e.connectorNumber;
                return true;
            }
        })
        setActiveDevice({
            id: activeDevice.project.devices[index]._id,
            assetId: activeDevice.project.devices[index].assetId._id,
            accessCategory: activeDevice.project.devices[index].assetId.accessCategory,
            coordinates: [activeDevice.project.devices[index].location.lon || 0, activeDevice.project.devices[index].location.lat || 0],
            chargeStatus: activeDevice.project.devices[index].assetId.connectors[connectorId - 1].chargingStatus.toLowerCase() || null,
            name: activeDevice.project.devices[index].deviceName || null,
            location: activeDevice.project.address || null,
            outputType: activeDevice.project.devices[index].assetId.outputType || null,
            capacity: activeDevice.project.devices[index].assetId.outputWattCategory || null,
            pricingPolicy: activeDevice.project.devices[index].assetId.pricingPolicy || null,
            connectorType: activeDevice.project.devices[index].assetId.connectors[connectorId - 1].connectorType || null,
            directions: '' || null,
            projectName: activeDevice.project.projectName || null,
            project: activeDevice.project,
            connectors: activeDevice.project.devices[index].assetId.connectors,
            connectorId: connectorId,
            reservedTime: activeDevice.project.devices[index].assetId.connectors[connectorId - 1]?.reservedTime
        })
        reservedUser.current = activeDevice.project.devices[index].assetId.connectors[connectorId - 1]?.reservedUser;
        return;
    }

    async function findDistance(startCoords, endCoords) {
        if (startCoords.length !== 2 || endCoords.length !== 2)
            return null;
        return await fetch('https://api.mapbox.com/directions/v5/mapbox/driving/' +
            startCoords[0] + ',' + startCoords[1] + ';' +
            endCoords[0] + ',' + endCoords[1] + '?access_token=' + mapboxgl.accessToken).then(response => response.json())
            .then(data => {
                return (data.routes[0].distance / 1000).toFixed(2);
            }).catch(err => { console.log(err); return null; })
    }
    useEffect(() => {
        if (!mapState) {
            boundsGl.current = new mapboxgl.LngLatBounds();
            let obj = initializeMap(mapContainer);
            mapGl.current = obj.map;
        }
        const timer = setInterval(() => {
            ProtectedAPI('/device/get/all/' + localStorage.getItem('userId'), { method: "GET" }).then(resp => {
                setProjects(resp.projects);
                return resp;
            })
        }, 3000000);
        return (() => {
            window.clearInterval(timer);
        })
    }, [])
    useEffect(() => {
        if (projects && mapGl.current?._loaded) {
            let { features } = renderDevices(projects);
            if (mapGl.current && mapState) {
                let _projects = {
                    type: "FeatureCollection",
                    features: features,
                }
                mapGl.current.getSource('deviceSource').setData(_projects);
            }
            if (activeDevice?.id) {
                let project = projects.find(e => e._id === activeDevice.project._id)
                project.devices = project.devices.filter(e => e.assetId.connectors.length > 0).sort((a, b) => {
                    return (b.assetId.statusNotification === 'Available') - (a.assetId.statusNotification === 'Available');
                })
                let [device] = project.devices.filter(e => e._id === activeDevice.id);
                setActiveDevice({
                    id: device._id,
                    assetId: device.assetId._id,
                    accessCategory: device.assetId.accessCategory,
                    coordinates: [device.location.lon || 0, device.location.lat || 0],
                    chargeStatus: device.assetId.connectors[activeDevice.connectorId - 1].chargingStatus.toLowerCase() || null,
                    name: device.deviceName || null,
                    location: project.address || null,
                    outputType: device.assetId.outputType || null,
                    capacity: device.assetId.outputWattCategory || null,
                    pricingPolicy: device.assetId.pricingPolicy || null,
                    connectorType: device.assetId.connectors[activeDevice.connectorId - 1].connectorType || null,
                    directions: '' || null,
                    projectName: project.projectName || null,
                    project: project,
                    connectors: device.assetId.connectors,
                    connectorId: activeDevice.connectorId,
                    reservedTime: device.assetId.connectors[activeDevice.connectorId - 1]?.reservedTime
                })
                reservedUser.current = device.assetId.connectors[activeDevice.connectorId - 1]?.reservedUser

            }
        }
    }, [projects])
    useEffect(() => {
        if (mapGl.current && projects) {
            let { features } = renderDevices(projects);
            let _projects = {
                type: "FeatureCollection",
                features: features,
            }
            mapGl.current.on("load", () => {
                mapGl.current.getSource('deviceSource').setData(_projects);
                features.forEach(function (feature) {
                    boundsGl.current.extend([feature.geometry.coordinates[0], feature.geometry.coordinates[1]]);
                    if (props.activeChargePoint) {
                        if (feature.properties.id === props.activeChargePoint.device._id) {
                            handleFeatureClick(mapGl.current, [feature])
                            if (props.state === true) props.state.activeDevice = false;
                        }
                    }
                });
                setActiveDevice((device) => { //Hotfix
                    if (!props.activeChargePoint && (device.name === '' && device.location === '') && boundsGl.current?._ne && boundsGl.current?._sw)
                        mapGl.current.fitBounds(boundsGl.current, { padding: 50 });
                    return device;
                })
            });
        }
        if (props.activeChargePoint !== null) {
            setActiveDevice({
                id: props.activeChargePoint.device._id,
                assetId: props.activeChargePoint.asset._id,
                accessCategory: props.activeChargePoint.asset.accessCategory,
                coordinates: [props.activeChargePoint.device.location.lon || 0, props.activeChargePoint.device.location.lat || 0],
                chargeStatus: props.activeChargePoint.asset.connectors[props.activeChargePoint.connectorId - 1].chargingStatus.toLowerCase() || 'unavailable',
                name: props.activeChargePoint.device.deviceName,
                location: props.activeChargePoint.device.address || null,
                outputType: props.activeChargePoint.asset.outputType || null,
                capacity: props.activeChargePoint.asset.outputWattCategory || null,
                pricingPolicy: props.activeChargePoint.asset.pricingPolicy || null,
                connectorType: props.activeChargePoint.asset.deviceConnectorType || null,
                directions: props.activeChargePoint.device.directionsDescription || null,
                projectName: props.activeChargePoint.project?.projectName || null,
                project: props.activeChargePoint.project,
                connectors: props.activeChargePoint.asset.connectors,
                connectorId: props.activeChargePoint.connectorId,
                reservedTime: props.activeChargePoint.asset.connectors[props.activeChargePoint.connectorId - 1]?.reservedTime
            })
            reservedUser.current = props.activeChargePoint.asset.connectors[props.activeChargePoint.connectorId - 1]?.reservedUser

        }
    }, [props.activeChargePoint])
    useEffect(() => {
        if (mapGl.current._loaded || activeDevice.id) {
            $('.deviceNavigators').css('bottom', activeDevice.location == null ? '275px' : '300px')
            if (activeDevice.name === '' && activeDevice.location === '') {
                mapGl.current.setLayoutProperty('deviceLayer', 'icon-image',
                    [
                        'match',
                        ['get', 'chargeStatus'],
                        'unavailable', 'chargepoint-unavailable',
                        'inactive', 'chargepoint-inactive',
                        'faulted', 'chargepoint-inactive',
                        'available', 'chargepoint-active',
                        'charging', 'chargepoint-inuse',
                        'preparing', 'chargepoint-inuse',
                        'finishing', 'chargepoint-inuse',
                        'suspendedevse', 'chargepoint-inuse',
                        'suspendedev', 'chargepoint-inuse',
                        'chargepoint-unavailable'
                    ]
                )
                $('#modalStationDetails').animate({ bottom: "10px", opacity: 0 }, () => {
                    $('#modalStationDetails').hide();
                    $('#modalStationDetails').css({ zIndex: -999 });
                })
                $('.projectModalContainer').animate({ bottom: "10px", opacity: 0 }, () => {
                    $('.projectModalContainer').hide();
                    $('.projectModalContainer').css({ zIndex: -999 });
                    $('.reserveInputs').hide();
                    $('.deviceButtons').show();
                    $('.cancelButton').show();
                    $('.expandButton').hide();
                    $('.deviceMetrics').fadeIn();
                    $('#modalStationDetails').hide();
                    $('#modalStationDetails').css({ zIndex: -999 });
                    $('.endSessionButton').hide().animate({ width: "0%", opacity: 0 });
                    $('.transactionButton').html('Start Session').removeClass('active preparing disabled');
                    $('.stationInvoiceContainer').fadeOut();
                    $('.energyOutput>span').html(0);
                })
            }
            else {
                if ($('#divDirections').filter(":visible").length === 0) {
                    mapGl.current.flyTo({
                        center: [activeDevice.coordinates[0], activeDevice.coordinates[1] - 0.00007],
                        speed: 10,
                        zoom: 21,
                        curve: 0.5,
                        essential: true,
                        easing(t) {
                            return t;
                        }
                    })
                }
                window.history.replaceState({}, document.title)
                $('#modalStationDetails').show();
                $('#modalStationDetails').css({ zIndex: 10 });
                $('#modalStationDetails').animate({ bottom: "25px", opacity: 1 })
                $('.projectModalContainer').show();
                $('.projectModalContainer').css({ zIndex: 10 });
                $('.projectModalContainer').animate({ bottom: "0px", opacity: 1 })
                if (activeDevice.chargeStatus === 'charging' && localStorage.getItem('activeSession'))
                    $('.projectModal').hide();
            }
        }
    }, [activeDevice])
    useEffect(() => {
        if (mapGl.current?._loaded) {
            mapGl.current.setLayoutProperty('deviceLayer', 'visibility', 'visible');
            mapGl.current.setLayoutProperty('deviceClusters', 'visibility', 'visible');
            mapGl.current.setLayoutProperty('device-count', 'visibility', 'visible');
            if (props.activeChargePoint !== null) {
                let project = { ...props.activeChargePoint.project }
                project.devices = (project.devices.filter(e => e.assetId.connectors.length > 0)).sort((a, b) => {
                    return (b.assetId.statusNotification === 'Available') - (a.assetId.statusNotification === 'Available');
                })
                let { features } = renderDevices([project]);
                let _devices = {
                    type: "FeatureCollection",
                    features: features,
                }
                mapGl.current.getSource('deviceSource').setData(_devices);
                let index = activeDevice.project.devices.findIndex(device => device._id === activeDevice.id)
                if (index === 0)
                    index = activeDevice.project.devices.length;
                index = (index) % activeDevice.project.devices.length;

                mapGl.current.setLayoutProperty('deviceLayer', 'icon-image',
                    [
                        'match',
                        ['get', 'chargeStatus'],
                        'unavailable',
                        [
                            'match',
                            ['id'],
                            index,
                            'chargepoint-selected',
                            'chargepoint-unavailable'
                        ],
                        'inactive',
                        [
                            'match',
                            ['id'],
                            index,
                            'chargepoint-selected',
                            'chargepoint-inactive'
                        ],
                        'faulted',
                        [
                            'match',
                            ['id'],
                            index,
                            'chargepoint-selected',
                            'chargepoint-inactive'
                        ],
                        'available',
                        [
                            'match',
                            ['id'],
                            index,
                            'chargepoint-selected',
                            'chargepoint-active'
                        ],
                        'charging',
                        [
                            'match',
                            ['id'],
                            index,
                            'chargepoint-selected',
                            'chargepoint-inuse'
                        ],
                        'preparing',
                        [
                            'match',
                            ['id'],
                            index,
                            'chargepoint-selected',
                            'chargepoint-inuse'
                        ],
                        'finishing',
                        [
                            'match',
                            ['id'],
                            index,
                            'chargepoint-selected',
                            'chargepoint-inuse'
                        ],
                        'suspendedevse',
                        [
                            'match',
                            ['id'],
                            index,
                            'chargepoint-selected',
                            'chargepoint-inuse'
                        ],
                        'suspendedev',
                        [
                            'match',
                            ['id'],
                            index,
                            'chargepoint-selected',
                            'chargepoint-inuse'
                        ],
                        'chargepoint-unavailable'
                    ]
                )
            }
        }
    }, [mapGl.current?._loaded])
    return (
        <div className="parentMapContainer" >
            <div id='divMap' ref={el => mapContainer = el} className='mapContainer' />
            <pre id="coordinates" className="coordinates"></pre>
            {activeDevice?.id &&
                <div className='projectModalContainer'>
                    {projects &&
                        <div className='projectModal'>
                            <h1>{activeDevice.projectName}</h1>
                            <div>
                                <i className="fa-solid fa-arrow-left" onClick={(e) => { switchStation(-1, e) }}></i>
                                <p><span>{(activeDevice?.project?.devices?.findIndex(device => device._id === activeDevice.id) || 0) + 1}</span> of <span>{activeDevice?.project?.devices?.length || 0}</span></p>
                                <i className="fa-solid fa-arrow-right" onClick={(e) => { switchStation(+1, e) }}></i>
                            </div>
                        </div>
                    }
                    <DeviceModal activeDevice={activeDevice} reservedUser={reservedUser} getReverseGeocode={getReverseGeocode} setActiveDevice={(activeDevice) => { setActiveDevice(activeDevice) }} loaded={mapGl.current?._loaded} updateProjects={async () => {
                        try {
                            let resp = await ProtectedAPI('/device/get/all/' + localStorage.getItem('userId'), { method: "GET" })
                            setProjects(resp.projects);
                            return resp;
                        } catch (err) {
                            console.log(err);
                            return null;
                        }
                    }} removePath={() => {
                        let routeData = {
                            type: 'FeatureCollection',
                            features: [],
                        };
                        if (mapGl.current?._loaded) mapGl.current.getSource('route').setData(routeData);
                    }} currentLocation={currentLocation}
                        findDistance={findDistance} />
                </div>
            }
        </div>
    );
}

export default GeoMap