import './App.scss';

import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements
} from "react-router-dom";
import React, { useEffect } from 'react';

import Account from './Account/Account';
import ContactUs from './ContactUs/ContactUs';
import Map from './Account/Map'
import Oops from './Error/Oops';
import Settings from './Account/Settings';
import Signin from './Authentication/Signin';
import Wallet from './Account/Wallet';

function App() {
  const setHeight = (element) => {
    let ele = document.getElementById(element)
    if (ele) ele.style.minHeight = window.innerHeight + "px";
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="" errorElement={<Oops />}>
        <Route index element={<Navigate to="client/auth/signin" />} errorElement={<Oops />} />
        <Route path="client/auth/signin" element={<Signin />} errorElement={<Oops />} />
        <Route path="client/device/:deviceId" element={<Signin />} errorElement={<Oops />} />
        <Route path="client/account/:userId" element={<Account />} errorElement={<Oops />}>
          <Route path="map" element={<Map />} errorElement={<Oops />} />
          <Route path="wallet" element={<Wallet />} errorElement={<Oops />} />
          <Route path="settings" element={<Settings />} errorElement={<Oops />} />
          <Route path="contactUs" element={<ContactUs />} errorElement={<Oops />} />
        </Route>
      </Route>
    )
  )

  useEffect(() => {
    if (window.top.location.href !== window.location.href) {
      window.top.location = window.location;
    }
    // set an event listener that detects when innerHeight changes:
    window.addEventListener("resize", setHeight);
    // call the function once to set initial height:
    setHeight('divContainer');
  }, [])

  return (
    <div id="divContainer" className='parentAppContainer'>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
