import { useEffect } from "react"
import { useMatches, useNavigate } from "react-router-dom";

import $ from 'jquery';

function ContactUs(props) {
    let navigate = useNavigate();
    let matches = useMatches();
    function copyToClipboard(e) {
        if (e.target?.dataset?.clipboard) {
            navigator.clipboard.writeText(e.target.dataset.clipboard);
        }
    }
    let handleCancelButton = () => {
        navigate(matches[1].pathname + '/map');
        $('.accountDetailsModal').animate({ height: '0px' }, () => {
            $('.accountDetailsModal').hide();
            $('.accountDetailsContainer').hide().css('z-index', -1);
        });
    }

    useEffect(() => {
        $('#divAccountContentContainer').on('click', (e) => {
            if (e.target.id === 'divAccountContentContainer') {
                navigate(matches[1].pathname + '/map');
                $('.accountDetailsModal').animate({ height: '0px' }, () => {
                    $('.accountDetailsModal').hide();
                    $('.accountDetailsContainer').hide().css('z-index', -1);
                    $("#divAccountContentContainer").prop("onclick", null).off("click");
                });
            }
        })
    }, [])
    return (<div className="contactUs">
        {/* <div className="card">
            <div className="header">
                <h1>About us</h1>
                <p>Wavefuel is a revolutionary force in the electric vehicle (EV) landscape. We offer a suite of cutting-edge apps designed to revolutionize EV charging and smart living.</p>
                <p>We would love to hear from you!</p>
            </div>
            <div className="body">
                <div className="detailContainer">
                    <i class="fa-solid fa-file-contract"></i>
                    <a href="https://wavefuel.in/terms-and-conditions.html" target='_blank'>Terms and Conditions</a>
                </div>
                <div className="detailContainer">
                    <i class="fa-solid fa-user-shield"></i>
                    <a href="https://wavefuel.in/privacy-policy.html" target='_blank'>Privacy and Policy</a>
                </div>
                <div className="detailContainer">
                    <i class="fa-solid fa-code-compare"></i>
                    <p>1.00.1</p>
                </div>
                <div className="detailContainer">
                    <i class="fa-solid fa-earth-asia"></i>
                    <a href="https://wavefuel.in" target='_blank'>www.wavefuel.in</a>
                </div>
            </div>
        </div> */}
        <div className="card">
            <div className="header">
                <h1>About Us</h1>
            </div>
            <div className="sub-header">
                <h1>Contact Us</h1>
            </div>
            <div className="body">
                <div className="detailContainer">
                    <i className="fa-solid fa-map-location-dot"></i>
                    <p>Plot No. 13, Phase II, Kavuri Hills, Madhapur, Hyderabad, Telangana 500081</p>
                </div>
                <div className="detailContainer">
                    <i className="fa-solid fa-phone"></i>
                    <p>+91 <a href="tel: +91868-688-4885" onClick={copyToClipboard} data-clipboard="9154194449">9154194449</a></p>
                </div>
                <div className="detailContainer">
                    <i className="fa-regular fa-envelope"></i>
                    <a href="mailto: info@wavefuel.in" onClick={copyToClipboard} data-clipboard="info@wavefuel.in">info@wavefuel.in</a>
                </div>
            </div>
            <div className="sub-header">
                <h1>Terms and Conditions</h1>
            </div>
            <div className="body">
                <div className="detailContainer">
                    <i className="fa-solid fa-user-shield"></i>
                    <a href="https://wavefuel.in/privacy-policy.html" target='_blank'>Privacy and Policy</a>
                </div>
                <div className="detailContainer">
                    <i className="fa-solid fa-hand-holding-dollar"></i>
                    <a href="https://wavefuel.in/privacy-policy.html" target='_blank'>Refund Policy</a>
                </div>
                <div className="detailContainer">
                    <i className="fa-solid fa-file-contract"></i>
                    <a href="https://wavefuel.in/terms-and-conditions.html" target='_blank'>Terms and Conditions</a>
                </div>
            </div>
            {/* <div className="footer">
                <div className="socialMedia">
                    <a className="fa-brands fa-facebook-f"></a>
                    <a className="fa-brands fa-twitter"></a>
                    <a className="fa-brands fa-instagram"></a>
                    <a className="fa-brands fa-linkedin-in"></a>
                </div>
            </div> */}
        </div>
        <i className="fa-regular fa-circle-xmark cancelButton" onClick={handleCancelButton}></i>
    </div>)
}

export default ContactUs