import { useEffect, useRef, useState } from "react"
import { useMatches, useNavigate } from 'react-router-dom';

import $ from 'jquery';
import CurrencyInput from "../../components/CurrencyInput/CurrencyInput";
import PaymentButton from "../../components/Wallet/PaymentButton";
import RadialChart from "../../components/Analytics/RadialChart";
import { getWallet } from '../../api/account.api';

let walletBalance;
function TransactionRow(props) {
    if (props.data.type === 'deduct') {
        return (
            <div className="row" key={props.item} onClick={() => {
                $('#divWalletContainer').fadeOut(500, () => {

                    let duration = props.data.duration;
                    let second = duration % 60;
                    let minute = ((duration - second) / 60) % 60;
                    let hour = (((duration - second) / 60) - minute) / 60;
                    hour = (hour < 10) ? "0" + hour : hour;
                    minute = (minute < 10) ? "0" + minute : minute;
                    second = (second < 10) ? "0" + second : second;

                    var date_str = props.data.startTime,
                        options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' },
                        formatted = (new Date(date_str)).toLocaleDateString('en-US', options),
                        date_parts = formatted.substring(0, formatted.indexOf(",")).split(" ").reverse().join(" ");
                    var formatted_date = date_parts + formatted.substr(formatted.indexOf(",") + 1);

                    let height = (window.screen.width > 430) ? "540px" : "470px";
                    height = (window.screen.height > 640) ? height : "380px";

                    $("#reservationInvoice").hide()
                    $("#walletRecharge").hide()
                    $('.accountDetailsModal').animate({ height: height, maxHeight: "100%" })
                    $(".invoiceHead > h1").text(props.data.name);
                    $(".invoiceInfo > p").text(props.data.address);
                    $("#durationBox").text(hour + ":" + minute + ":" + second);
                    $('#energyId').html((props.data.energyUsage / 1000).toFixed(2));
                    $("#duration > .val").text(hour + ":" + minute + ":" + second);
                    $('#energy > .val').html((props.data.energyUsage / 1000).toFixed(2) + ' kwh');
                    $("#startTime > .val").text(formatted_date);
                    $('#connectorType > .val').text(props.data.connectorType);
                    $("#sessionTariff > .val").text('₹' + parseFloat(props.data.sessionTariff + props.data.pricingPolicy).toFixed(2));
                    $("#serviceFee > .val").text('₹' + parseFloat(props.data.serviceFee.price).toFixed(2));
                    $("#chargeReservationFee > .val").text('-₹' + parseFloat(props.data.reservationFee).toFixed(2));
                    $("#chargingFee > .val").text('₹' + parseFloat((props.data.sessionTariff + props.data.pricingPolicy) * (props.data.energyUsage / 1000)).toFixed(2));
                    $("#amount > .val").text('₹' + parseFloat(props.data.amount).toFixed(2));
                    $("#paymentStatus >.val").text("Success");
                    $("#paymentMethod >.val").text("E-Wallet");
                    $('#chargeInvoice').fadeIn()
                });
            }}>
                <div className="category">
                    <h1>Charger</h1>
                    <p>{(new Date(props.data.transactionStartDate)).toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                    })}</p>
                </div>
                <div className="details">
                    <h1>{props.data.name}</h1>
                    <p><span>{props.data.connectorType}</span>-<span>{props.data.outputWattCategory}</span></p>
                </div>
                <div className={"amount " + props.data.type}>
                    <h1>{'₹' + props.data.amount}</h1>
                    <p>{parseFloat((props.data.energyUsage / 1000).toFixed(2)) + 'kW'}</p>
                </div>
            </div>
        )
    }
    else if (props.data.type === 'reservation') {
        return (
            <div className="row" key={props.item} onClick={() => {
                $('#divWalletContainer').fadeOut(500, () => {

                    let height = (window.screen.width > 430) ? "385px" : "346px";
                    height = (window.screen.height > 640) ? height : "264px";

                    var date_str = props.data.transactionStartDate,
                        options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' },
                        formatted = (new Date(date_str)).toLocaleDateString('en-US', options),
                        date_parts = formatted.substring(0, formatted.indexOf(",")).split(" ").reverse().join(" ");
                    var formatted_date = date_parts + formatted.substr(formatted.indexOf(",") + 1);

                    $("chargeInvoice").hide()
                    $("walletRecharge").hide()
                    $('.accountDetailsModal').animate({ height: height, maxHeight: "100%" })
                    $(".invoiceHead > h1").text(props.data.name);
                    $(".invoiceInfo > p").text(props.data.address);
                    $("#date > .val").text(formatted_date);
                    $('#connectorType > .val').text(props.data.connectorType);
                    $("#residenceType >.val").text(props.data.residenceType || 'Residence');
                    $("#reservationFee > .val").text('₹' + parseFloat(props.data.amount).toFixed(2));
                    $("#amount > .val").text('₹' + parseFloat(props.data.amount).toFixed(2));
                    $("#paymentStatus >.val").text("Success");
                    $("#paymentMethod >.val").text("E-Wallet");
                    $('#reservationInvoice').fadeIn();
                });
            }}>
                <div className="category">
                    <h1>Reservation</h1>
                    <p>{(new Date(props.data.transactionStartDate)).toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                    })}</p>
                </div>
                <div className="details">
                    <h1>{props.data.name}</h1>
                    <p><span>{props.data.connectorType}</span>-<span>{props.data.outputWattCategory}</span></p>
                </div>
                <div className={"amount deduct"}>
                    <h1>{'₹' + props.data.amount}</h1>
                </div>
            </div>
        )
    }
    else {
        return (
            <div className="row" key={props.item} onClick={() => {
                $('#divWalletContainer').fadeOut(500, () => {

                    let height = (window.screen.width > 430) ? "415px" : "350px";
                    height = (window.screen.height > 640) ? height : "267px";

                    var date_str = props.data.transactionStartDate,
                        options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' },
                        formatted = (new Date(date_str)).toLocaleDateString('en-US', options),
                        date_parts = formatted.substring(0, formatted.indexOf(",")).split(" ").reverse().join(" ");
                    var formatted_date = date_parts + formatted.substr(formatted.indexOf(",") + 1);

                    $("#chargeInvoice").hide();
                    $("#reservationInvoice").hide();
                    $('.accountDetailsModal').animate({ height: height, maxHeight: "100%" })
                    $(".invoiceHead > h1").text("Top-Up");
                    $("#date > .val").text(formatted_date);
                    $("#paymentId > .val").text(props.data.paymentId);
                    $("#referenceId > .val").text(props.data.id);
                    $("#rechargedAmount > .val").text('₹' + (parseFloat(props.data.amount) * 1.18).toFixed(2));
                    $("#cgst > .val").text('-₹' + parseFloat((props.data.amount * 0.09)).toFixed(2));
                    $("#sgst > .val").text('-₹' + parseFloat((props.data.amount * 0.09)).toFixed(2));
                    $("#addedAmount > .val").text('₹' + parseFloat(props.data.amount).toFixed(2));
                    $("#totalBalance > .val").text('₹' + parseFloat(walletBalance).toFixed(2));
                    $("#paymentStatus >.val").text("Success");
                    $("#paymentMethod >.val").text("E-Wallet");
                    $('#walletRecharge').fadeIn();
                });
            }}>
                <div className="category">
                    <h1>Wallet</h1>
                    <p>{(new Date(props.data.transactionStartDate)).toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                    })}</p>
                </div>
                <div className="details">
                    <h1>Wallet Topup</h1>
                    <p>{'Ref: ' + props.data.ref}</p>
                </div>
                <div className={"amount " + props.data.type}>
                    <h1>{'₹' + parseFloat(props.data.amount).toFixed(2)}</h1>
                </div>
            </div>
        )
    }
}
function Wallet() {
    const username = localStorage.getItem('name');
    const phoneNumber = localStorage.getItem('phoneNumber');
    const uid = localStorage.getItem('userId');
    const [transactionList, setTransactionList] = useState([]);
    let navigate = useNavigate();
    let matches = useMatches();
    let walletChartRef = useRef();
    let data = useRef([
        {
            name: 'Office',
            y: 0,
            color: "#0058FF"
        },
        {
            name: 'Muncipal',
            y: 0,
            color: "#21D59B"
        },
        {
            name: 'Parking',
            y: 0,
            color: "#F99600"
        },
        {
            name: 'Retail',
            y: 0,
            dataLabels: {
                enabled: false
            }
        },
        {
            name: 'Residential',
            y: 0,
            dataLabels: {
                enabled: false
            }
        }
    ]);
    let WalletChartOptions = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: 0,
            plotShadow: false,
            backgroundColor: '#93a1b200',
        },
        title: {
            text: '',
            align: 'center',
            style: { "font-size": "14px", "font-weight": 'bold' }
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    enabled: false,
                    distance: -50,
                    style: {
                        fontWeight: 'bold',
                        color: 'white'
                    }
                },
                startAngle: -180,
                endAngle: 180,
                center: ['50%', '50%'],
                size: '90%',
                borderColor: '#ffffff00'
            }
        },
        legend: {
            enabled: true,
            labelFormat: '{name}',
            layout: 'horizontal',
            floating: true,
            itemStyle: { "fontSize": "10px" },
            align: "center",
            y: 20,
            itemDistance: 3,
        },
        credits: {
            enabled: false
        },
        series: [{
            type: 'pie',
            name: 'Usage Breakdown',
            innerSize: '90%',
            showInLegend: true,
            data: data.current,
        }]
    };
    useEffect(() => {
        if (phoneNumber && uid && username) {
            $('.topupDefaults>div').on('click', (e) => {
                let value = e.target.innerHTML;
                let options = {
                    maximumFractionDigits: 2,
                    currency: 'INR',
                    style: "currency",
                    currencyDisplay: "symbol"
                }

                let inputVal = (value || value === 0)
                    ? Number(String(value).replace(/[^0-9.-]+/g, "")).toLocaleString(undefined, options)
                    : ''
                $('.topupInput>input').val(inputVal).trigger('input');
                $("#addAmount").html('₹' + parseFloat(value / 1.18).toFixed(2));
            })
            getWallet().then((resp) => {
                if (resp.wallet) {
                    walletBalance = resp.wallet.balance;
                    let length = ("" + Math.floor(walletBalance)).length;
                    let textSize = 75 - (length * 5);
                    textSize = window.screen.height > 640 ? textSize : textSize - 20;
                    $('.walletBalance>h1').html('₹' + Math.floor(walletBalance));
                    $(".walletBalance>h1").css("fontSize", textSize);
                    setTransactionList(resp.transactionList);
                    for (let transaction of resp.transactionList) {
                        data.current.forEach((e, i) => {
                            if (e.name === (transaction.residenceType || 'Residential'))
                                data.current[i].y++;
                        })
                    }
                    $('#modalStationDetails > .cancelButton')[0]?.click();
                }
                else {
                    $('.walletBalance>h1').html('₹' + 0);
                }
            }).catch(err => {
                console.log(err);
            })
        }
        else {
            localStorage.clear();
            navigate('/client/auth/signin');
        }
        $('#divAccountContentContainer').on('click', (e) => {
            if (e.target.id === 'divAccountContentContainer') {
                $('.accountDetailsModal').animate({ height: '0px' }, () => {
                    $('.accountDetailsModal').hide();
                    $('.accountDetailsContainer').hide().css('z-index', -1);
                    $("#divAccountContentContainer").prop("onclick", null).off("click");
                });
            }
        })

        $("#topupAmount").on('input', function () {
            $('.topupDefaults').hide();
            $('.addTextField').show();
            let amount = $("#topupAmount").val();
            amount = Number(amount.replace(/[^0-9.-]+/g, ""))
            if (amount === '') {
                $('.addTextField').hide();
                $('.topupDefaults').show();
            }
            else if (amount < 10 || amount > 10000 || !amount) {
                $("#addAmount").html('Invalid');
            }
            else {
                $("#addAmount").html('₹' + parseFloat(amount / 1.18).toFixed(2));
            }
        });

        $('.addTextField').hide();
        $('.topupDefaults').show();
        $('#chargeInvoice').hide();
        $('#chargeInvoice > #stationInvoice').show().css('opacity', '1');
        $('#reservationInvoice').hide();
        $('#reservationInvoice > #stationInvoice').show().css('opacity', '1');
        $('#walletRecharge').hide();
        $('#walletRecharge > #stationInvoice').show().css('opacity', '1');
    }, [])
    return (
        <>
            <div id="divWalletContainer" className="profileDetailContainer">
                <i className="fa-regular fa-circle-xmark cancelButton" onClick={() => {
                    navigate(matches[1].pathname + '/map');
                    $('.accountDetailsModal').animate({ height: '0px' }, () => {
                        $('.accountDetailsModal').hide();
                        $('.accountDetailsContainer').hide().css('z-index', -1);
                    });
                }}></i>
                <div className="analytic walletAnalytic">
                    <div className="walletBalance">
                        <p>Current Balance</p>
                        <h1>₹0</h1>
                    </div>
                    <RadialChart options={WalletChartOptions} ref={walletChartRef} containerProps={{ className: 'chart-wallet' }} />
                </div>
                <div className="walletButtons">
                    <div>
                        <div className="topupInput deposit">
                            <CurrencyInput id="topupAmount" placeholder='TopUp Your Balance' disabled={false} />
                            <div className="topupDefaults">
                                <div>500</div>
                                <div>1000</div>
                                <div>1500</div>
                                <div>2000</div>
                            </div>
                            <div className="addTextField">
                                <p>With Tax Deductions: </p>
                                <p id="addAmount">₹ 0</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <PaymentButton phoneNumber={phoneNumber} username={username} uid={uid} setTransactionList={(tl) => { setTransactionList(tl) }} />
                    </div>
                </div>
                <div className="transactionHistory tableContainer">
                    <h1>Transaction History</h1>
                    <div className="transactionTable">
                        {transactionList.map((e, i) => {
                            return <TransactionRow data={e} item={i} key={i} />
                        })}
                    </div>
                </div>
            </div>
            <div id="chargeInvoice" className="showInvoice">
                <div id="stationInvoice" className="walletInvoiceContainer">
                    <div className="invoiceInfo">
                        <div className="invoiceHead">
                            <h1>Device Name</h1>
                            <i className="fa-regular fa-circle-xmark" onClick={() => {
                                $('#chargeInvoice').fadeOut(500, () => {
                                    $('#walletRecharge').hide();
                                    $('#reservationInvoice').hide();
                                    var nextHeight = $(window).height() * 0.93 - 95;
                                    $('.accountDetailsModal').animate({ height: nextHeight, maxHeight: "100%" });
                                    $('#divWalletContainer').fadeIn();
                                    if ($("#topupAmount").val() === 0) {
                                        $('.addTextField').hide();
                                        $('.topupDefaults').show();
                                    }
                                })
                            }}></i>
                        </div>
                        <p className="invoiceAddress">----</p>
                    </div>
                    <div className="invoiceDetails">
                        <div className="status">
                            <i className='fa-solid fa-bolt'></i>
                            <p>END</p>
                        </div>
                        <div className='divider'></div>
                        <div className="duration">
                            <p id="durationBox">00:00:00</p>
                        </div>
                        <div className='divider'></div>
                        <div>
                            <div className="energy"><span id="energyId">0</span> kWh</div>
                        </div>
                    </div>
                    <div className='invoiceWalletSection'>
                        <h1>Session Details</h1>
                        <div className="invoiceSectionDetails">
                            <div id="duration">
                                <p>Duration</p>
                                <div className='invoiceDivider'></div>
                                <p className='val'>00:00:00</p>
                            </div>
                            <div id="energy">
                                <p>Energy Delivered</p>
                                <div className='invoiceDivider'></div>
                                <p className='val'>0kwh</p>
                            </div>
                            <div id="startTime">
                                <p>Session Start Time</p>
                                <div className='invoiceDivider'></div>
                                <p className='val'>--</p>
                            </div>
                            <div id="sessionTariff">
                                <p>Session Tariff (per kWh)</p>
                                <div className='invoiceDivider'></div>
                                <p className='val'>₹ 5.00</p>
                            </div>
                        </div>
                    </div>
                    <div className='invoiceWalletSection'>
                        <h1>Billing Details</h1>
                        <div className='invoiceSectionDetails'>
                            <div id="serviceFee">
                                <p>Service Fee</p>
                                <div className='invoiceDivider'></div>
                                <p className='val'>₹ 0</p>
                            </div>
                            <div id="chargeReservationFee">
                                <p>Reservation Fee</p>
                                <div className='invoiceDivider'></div>
                                <p className='val'>-₹ 0</p>
                            </div>
                            <div id="chargingFee">
                                <p>Charging Fee</p>
                                <div className='invoiceDivider'></div>
                                <p className='val'>₹ 0</p>
                            </div>
                        </div>
                    </div>
                    <div className='invoiceWalletSection'>
                        <h1>Payment Details</h1>
                        <div className='invoiceSectionDetails'>
                            <div id="amount">
                                <p>Total Amount</p>
                                <div className='invoiceDivider'></div>
                                <p className='val'>₹ 0</p>
                            </div>
                            <div id="paymentStatus">
                                <p>Payment Status</p>
                                <div className='invoiceDivider'></div>
                                <p className='val'>Success</p>
                            </div>
                            <div id="paymentMethod">
                                <p>Payment Method</p>
                                <div className='invoiceDivider'></div>
                                <p className='val'>E-Wallet</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="reservationInvoice" className="showInvoice">
                <div id="stationInvoice" className='walletInvoiceContainer'>
                    <div className="invoiceInfo">
                        <div className="invoiceHead">
                            <h1>Device Name</h1>
                            <i className="fa-regular fa-circle-xmark" onClick={() => {
                                $('#chargeInvoice').fadeOut(500, () => {
                                    $('#walletRecharge').hide();
                                    $('#reservationInvoice').hide();
                                    var nextHeight = $(window).height() * 0.93 - 95;
                                    $('.accountDetailsModal').animate({ height: nextHeight, maxHeight: "100%" });
                                    $('#divWalletContainer').fadeIn();
                                    if ($("#topupAmount").val() === 0) {
                                        $('.addTextField').hide();
                                        $('.topupDefaults').show();
                                    }
                                })
                            }}></i>
                        </div>
                        <p className="invoiceAddress">----</p>
                    </div>
                    <div className='invoiceWalletSection'>
                        <h1>Reservation Details</h1>
                        <div className='invoiceSectionDetails'>
                            <div id="date">
                                <p>Date</p>
                                <div className='invoiceDivider'></div>
                                <p className='val'>00/00/00</p>
                            </div>
                            <div id="connectorType">
                                <p>Connector Type</p>
                                <div className='invoiceDivider'></div>
                                <p className='val'>NA</p>
                            </div>
                            <div id="residenceType">
                                <p>Residence Type</p>
                                <div className='invoiceDivider'></div>
                                <p className='val'>NA</p>
                            </div>
                        </div>
                    </div>
                    <div className='invoiceWalletSection'>
                        <h1>Billing Details</h1>
                        <div className='invoiceSectionDetails'>
                            <div id="reservationFee">
                                <p>Reservation Fee</p>
                                <div className='invoiceDivider'></div>
                                <p className='val'>₹ 0</p>
                            </div>
                        </div>
                    </div>
                    <div className='invoiceWalletSection'>
                        <h1>Payment Details</h1>
                        <div className='invoiceSectionDetails'>
                            <div id="amount">
                                <p>Total Amount</p>
                                <div className='invoiceDivider'></div>
                                <p className='val'>₹ 0</p>
                            </div>
                            <div id="paymentStatus">
                                <p>Payment Status</p>
                                <div className='invoiceDivider'></div>
                                <p className='val'>Success</p>
                            </div>
                            <div id="paymentMethod">
                                <p>Payment Method</p>
                                <div className='invoiceDivider'></div>
                                <p className='val'>E-Wallet</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="walletRecharge" className="showInvoice">
                <div id="stationInvoice" className='walletInvoiceContainer'>
                    <div className="invoiceInfo">
                        <div className="invoiceHead">
                            <h1>Top-Up</h1>
                            <i className="fa-regular fa-circle-xmark" onClick={() => {
                                $('#chargeInvoice').fadeOut(500, () => {
                                    $('#walletRecharge').hide();
                                    $('#reservationInvoice').hide();
                                    var nextHeight = $(window).height() * 0.93 - 95;
                                    $('.accountDetailsModal').animate({ height: nextHeight, maxHeight: "100%" });
                                    $('#divWalletContainer').fadeIn();
                                    if ($("#topupAmount").val() === 0) {
                                        $('.addTextField').hide();
                                        $('.topupDefaults').show();
                                    }
                                })
                            }}></i>
                        </div>
                    </div>
                    <div className='invoiceWalletSection'>
                        <div className='invoiceSectionDetails'>
                            <h1>Transaction Details</h1>
                            <div id="date">
                                <p>Date</p>
                                <div className='invoiceDivider'></div>
                                <p className='val'>00/00/00</p>
                            </div>
                            <div id="paymentId">
                                <p>Payment Id</p>
                                <div className='invoiceDivider'></div>
                                <p className='val'>NA</p>
                            </div>
                            <div id="referenceId">
                                <p>Reference Id</p>
                                <div className='invoiceDivider'></div>
                                <p className='val'>NA</p>
                            </div>
                            <h1>Payment Details</h1>
                            <div id="rechargedAmount">
                                <p>Recharged Amount</p>
                                <div className='invoiceDivider'></div>
                                <p className='val'>₹ 0</p>
                            </div>
                            <div id="cgst">
                                <p>CGST (9%)</p>
                                <div className='invoiceDivider'></div>
                                <p className='val'>₹ 0</p>
                            </div>
                            <div id="sgst">
                                <p>SGST (9%)</p>
                                <div className='invoiceDivider'></div>
                                <p className='val'>-₹ 0</p>
                            </div>
                            <div id="addedAmount">
                                <p>Amount Added</p>
                                <div className='invoiceDivider'></div>
                                <p className='val'>-₹ 0</p>
                            </div>
                            <div id="paymentStatus">
                                <p>Payment Status</p>
                                <div className='invoiceDivider'></div>
                                <p className='val'>Success</p>
                            </div>
                            <div id="paymentMethod">
                                <p>Payment Method</p>
                                <div className='invoiceDivider'></div>
                                <p className='val'>E-Wallet</p>
                            </div>
                        </div>
                    </div>
                    <div className="info">
                        <i className='fa fa-circle-exclamation'></i>
                        <p>As Taxes are collected upfront, Taxes will not be deducted for EV Charging Sessions done using Spotlight account.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Wallet