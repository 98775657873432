import React, { useEffect, useState } from "react";

import { getUser } from '../account.api';
import { useNavigate } from "react-router-dom"

function AuthGuard(props) {
    let [Auth, setAuth] = useState({ uid: null, username: null, role: null, loading: false, token: null, status: true });
    let navigate = useNavigate();
    let handleAuthentication = () => {
        if(localStorage.getItem("exp") && localStorage.getItem("authorization") && localStorage.getItem("userId") && localStorage.getItem("phoneNumber") && localStorage.getItem("name") && localStorage.getItem("customer")) {
            getUser().then(resp => {
                if(resp.user.activeSession) localStorage.setItem('activeSession', resp.user.activeSession);
                setAuth({ uid: localStorage.getItem("userId"), username: localStorage.getItem("phoneNumber"),loading: false, status: true, session: resp.user.activeSession});
            })
        }
        else {
            localStorage.clear();
            navigate('/client/auth/signin');
        }
    }
    useEffect(() => {
        handleAuthentication();
    }, []);
    if (Auth.status === true) {
        return (
            <props.component {...props} auth={Auth} />
        )
    }

}
export default AuthGuard