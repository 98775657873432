import $ from 'jquery';
import { useEffect } from "react";
import { useRef } from "react";

function CurrencyInput(props) {
    let inputRef = useRef();
    var currency = 'INR'

    function localStringToNumber(s) {
        let amount = $("#topupAmount").val();
        amount = Number(amount.replace(/[^0-9.-]+/g, ""))
        if (amount==''){
            $('.addTextField').hide();
            $('.topupDefaults').show();
        }
        else if(amount<10 || amount>10000 || !amount){
            $("#addAmount").html('Invalid');
        }
        else {
            $("#addAmount").html('₹'+parseFloat(amount / 1.18).toFixed(2));
        }
        return Number(String(s).replace(/[^0-9.-]+/g, ""))
    }

    function onFocus(e) {
        var value = e.target.value;
        e.target.value = value ? localStringToNumber(value) : ''
    }

    function onBlur(e) {
        var value = e.target.value

        var options = {
            maximumFractionDigits: 2,
            currency: currency,
            style: "currency",
            currencyDisplay: "symbol"
        }

        e.target.value = (value || value === 0)
            ? localStringToNumber(value).toLocaleString(undefined, options)
            : ''
    }
    useEffect(() => {
        onBlur({ target: inputRef.current })
        inputRef.current.addEventListener('focus', onFocus)
        inputRef.current.addEventListener('blur', onBlur)
        inputRef.current.addEventListener('change', onBlur)
    }, [])
    return (
        <input id={props.id} type='currency' ref={inputRef} placeholder={props.placeholder} className={props.className} disabled={props?.disabled || false}/>
    )
}

export default CurrencyInput