import { cancelSessionAPI, checkConnectorStatus, getMeterValues, getSession, remoteStart, remoteStop } from '../../api/device.api.js'
import { useEffect, useRef, useState } from 'react';
import { useMatches, useNavigate } from "react-router-dom";

import $ from 'jquery';
import Invoice from './Invoice.js'
import Stopwatch from '../Stopwatch';
import { center } from '@turf/turf';

let scene;//HotFix
function DeviceModal(props) {
    const [transactionState, setTransactionState] = useState(false);
    const [deviceState, setDeviceState] = useState(props.activeDevice.chargeStatus);
    const [activeSession, setActiveSession] = useState(null);
    const [invoice, setInvoice] = useState(null);
    const [socket, setSocket] = useState(null);
    const [startTime, setStartTime] = useState(0);
    const [timerDirection, setTimerDirection] = useState(1);
    const [stopwatchState, setstopwatchState] = useState(null)
    const _activeSession = useRef(null);
    const [directions, setDirections] = useState([]);
    const [connectors, setConnectors] = useState([]);
    const [rerender, setRerender] = useState(false);
    const [distance, setDistance] = useState(null);
    const direc = useRef([0, 0])
    let navigate = useNavigate();
    let matches = useMatches();
    let selectedConnector = useRef();
    let chargerTypes = {
        'type2': 'Type 2',
        'chademo': 'CHADEMO',
        'type1': 'Type1',
        '3pin': '3 Pin',
        'ccs1': 'CCS 1',
        'ccs2': 'CCS 2',
        'bharatac': 'Bharat AC'
    }
    let interval = useRef();
    function closeDeviceModal() {
        if (!transactionState && props.reservedUser.current !== localStorage.getItem("userId")) {
            if (scene !== "device")
                scene = "device"
            $('.projectModalContainer').animate({ bottom: "10px", opacity: 0 }, () => {
                $('.projectModalContainer').hide();
                $('.projectModalContainer').css({ zIndex: -999 });
                $('.reserveInputs').hide();
                $('.deviceButtons').show();
                $('.cancelButton').hide();
                $('.minimiseButton').hide();
                $('.expandButton').hide();
                $('.transactionMessage').hide();
                $('.deviceMetrics').fadeIn();
                $('#modalStationDetails').hide();
                $('#modalStationDetails').css({ zIndex: -999 });
                $('.endSessionButton').hide().animate({ width: "0%", opacity: 0 });
                $('.transactionButton').html('Start Session').removeClass('active preparing disabled').animate({ width: "100%" })
                $('.stationInvoiceContainer').fadeOut();
                $('.energyOutput>span').html(0);
                props.setActiveDevice({
                    id: null,
                    assetId: null,
                    coordinates: [0, 0],
                    chargeStatus: null,
                    name: '',
                    location: '',
                    outputType: null,
                    capacity: null,
                    pricingPolicy: null,
                    connectorType: null,
                    directions: null,
                    project: null,
                    connectors: [],
                    connectorId: null,
                })
                props.reservedUser.current = null;
            })
            _activeSession.current = null
            if (socket) socket.close();
        }
    }
    function resetModal() {
        scene = (props.reservedUser.current || activeSession) ? "transaction" : "device"
        _activeSession.current = null
        if (socket) socket.close();
        setTransactionState(false);
        setDeviceState((props.reservedUser.current && props.reservedUser.current !== localStorage.getItem("userId")) ? "reserved" : props.activeDevice.chargeStatus);
        setActiveSession(null);
        setInvoice(null);
        setTimerDirection(1);
        setStartTime(10);
        setSocket(null);
        setstopwatchState('reset');
        let types = ['type2', 'chademo', 'type1', '3pin', 'ccs1', 'ccs2']
        let availability = {
            'available': 'availableConnector',
            'faulted': 'faultedConnector',
            'unavailable': 'unavailableConnector',
        }
        setConnectors(props.activeDevice.connectors.map(e => {
            return (
                <div className={'optionsContainer ' + (e.connectorNumber === selectedConnector.current ? 'selected ' : '') + (availability[(e.reservedUser) ? "charging" : e.chargingStatus.toLowerCase()])} data-connectornumber={e.connectorNumber} onClick={handleConnector}>
                    <p>{chargerTypes[e.connectorType.toLowerCase()]}</p>
                    <img src={types.includes(e.connectorType.toLowerCase()) ? require('../../assets/deviceModal/' + e.connectorType.toLowerCase() + '.svg') : require('../../assets/deviceModal/default.png')}></img>
                </div>
            )
        }));
        props.findDistance(props.activeDevice.coordinates, props.currentLocation).then(resp => {
            let dist = (parseFloat(resp) || 0).toFixed(0);
            setDistance(dist);
            $('.directionsButton').html(' <i className="fa-solid fa-diamond-turn-right"></i> ' + (dist || '-') + ' KM'); // Hotfix
        });
    }
    function createRealTimeSocket() {
        const ws = new WebSocket(process.env.REACT_APP_REALTIMESOCKETURL + props.activeDevice.assetId, 'realtime');
        let a = process.env.REACT_APP_REALTIMESOCKETURL + props.activeDevice.assetId + '/?userId=' + localStorage.getItem('userId');
        setSocket(ws);
        ws.onopen = () => {
            scene = "transaction"
            console.log('Real Time Socket Connection Open');
            let statusNotificationMessage = { action: "getStatusNotification", connectorId: props.activeDevice.connectorId }
            ws.send(JSON.stringify(statusNotificationMessage));
        };
        ws.onmessage = (message) => {
            let data = JSON.parse(message.data);
            scene = "transaction"
            console.log('Message received from Realtime Socket Server server.');
            if (data.action === 'statusNotification' && data.chargingStation) {
                let status = data.chargingStation.connectors[props.activeDevice.connectorId - 1].chargingStatus.toLowerCase();
                setDeviceState((state) => {
                    if (state === 'charging' && (status !== 'suspendedev' && status !== 'suspendedevse' && status !== 'charging' && status !== 'disconnected')) {
                        endSession(null, 'Your session has been <span>abruptly stopped</span> due to <span>Request made by Charger</span>');
                    }
                    return status;
                })
            }
            else if (data.action === 'activeSession') {
                _activeSession.current = data.session;
                setActiveSession(data.session);
                if (data.user.activeSession) localStorage.setItem('activeSession', data.user.activeSession);
                if (data?.session?.stopTransaction) {
                    if (data.reason && data.reason !== 'Remote' && data.reason !== '') {
                        endSession(null, 'Your session has been <span>abruptly stopped</span> due to <span>Request made by Charger</span>');
                    }
                }
            }
            else if (data.action === 'meterValues') {
                if (props.activeDevice.connectorId === data.session.connectorId) {
                    _activeSession.current = data.session;
                    setActiveSession(data.session);
                    $('.energyOutput>span').html((data.response.EnergyImport / 1000).toFixed(2));
                }
            }
            else if (data.action === 'walletBalanceStop') {
                $('.status').removeClass('active preparing inactive').addClass('inactive');
                setDeviceState('walletInsufficient');
                if (_activeSession.current._id) {
                    if (data.underFifty) {
                        setstopwatchState('pause');
                        endSession(null, 'Your session has been <span>abruptly stopped</span> due to <span>Insufficient Wallet Balance</span>');
                    }
                    // getMeterValues(_activeSession.current._id).then(resp => {
                    //     let respMod = handleMeterEndSessionMetrics(resp);
                    //     setInvoice({
                    //         duration: respMod[0],
                    //         energy: respMod[1],
                    //         startTime: respMod[2],
                    //         tariff: respMod[3],
                    //         fee: respMod[4],
                    //         tax: respMod[5],
                    //         amount: respMod[6],
                    //         paymentStatus: respMod[7],
                    //         paymentMethod: respMod[8]
                    //     })
                    //     if (resp.status === 200) {
                    //         let energy = (Number(resp.EnergyImport) / 1000).toFixed(2)
                    //         $('.energyOutput>span').html(energy);
                    //     }
                    // }).catch(err => {
                    //     console.log(err);
                    // })
                }
            }
        };
        ws.onclose = () => {
            console.log('Real Time Socket Connection Closed');
            _activeSession.current = null
        };
        ws.onerror = () => {
            ws.isAlive = false;
            ws.close();
        }
    }
    function handleExpandButton() {
        if (scene === "transaction") {
            let height = (window.screen.width > 430) ? "310.4px" : "257.4px";
            height = (window.screen.height > 600) ? height : "238.6px";
            $('.deviceModal').animate({ height: height }, 200, () => {
                if (transactionState || (props.reservedUser.current === localStorage.getItem("userId"))) {
                    $('.cancelButton').hide();
                    $('.minimiseButton').css('right', '10px').show();
                }
                else {
                    $('.cancelButton').show();
                    $('.minimiseButton').show();
                }
                $('.directions').fadeIn();
                $('.deviceMetrics').fadeIn();
                $('.sessionButtons').fadeIn();
                $(".reserveOptionsContainer").hide();
                $('.transactionMessage').hide();
                $('.expandButton').hide();
                $(".projectModal").hide();
                $('.deviceButtons').hide();
                $(".meterContainer").fadeIn()
            });
        }
        else {
            let height = (window.screen.width > 430) ? "336.4px" : "276px";
            height = (window.screen.height > 640) ? height : "238.6px";
            $('.deviceModal').animate({ height: height }, 200, () => {
                $('.cancelButton').show();
                $('.minimiseButton').css('right', '40px').show();
                $('.expandButton').hide();
                $('.directions').fadeIn();
                $('.reserveOptionsContainer').fadeIn();
                $('.deviceButtons').fadeIn();
                $(".deviceMetrics").fadeIn();
                $(".sessionButtons").hide();
                $(".meterContainer").hide();
            });
        }
    }
    function handleMinimiseButton() {

        if (scene === "transaction" || transactionState) {
            let h = $(".deviceModal").css("height", "auto").height();
            let height = (window.screen.width > 430) ? "124.6px" : "103px";
            $('.deviceModal').animate({ height: height }, 200, () => {
                $('.cancelButton').hide();
                $('.minimiseButton').hide();
                $(".reserveOptionsContainer").hide();
                $('.directions').hide();
                $('.deviceMetrics').hide();
                $('.deviceButtons').hide();
                $('.sessionButtons').hide();
                $('.expandButton').show();
                $(".projectModal").hide();
                $(".meterContainer").fadeIn();
                $('.transactionMessage').hide();
            });
        }
        else {
            let height = (window.screen.width > 430) ? "155.4px" : "141.2px";
            height = (window.screen.height > 640) ? height : "112.2px";
            $('.deviceModal').animate({ height: height }, 200, () => {
                $(".projectModal").fadeIn();
                $('.directions').fadeIn();
                $('.deviceMetrics').fadeIn();
                $('.cancelButton').hide();
                $('.minimiseButton').hide();
                $('.reserveOptionsContainer').hide();
                $('.deviceButtons').hide();
                $('.sessionButtons').hide();
                $('.expandButton').show();
                $(".meterContainer").hide();
            });
        }
    }

    function handleReserveButton(event) {
        if (event.detail !== 1)
            return;
        $(event.target).html('<i class="fa-solid fa-spinner fa-spin"></i>');
        $(event.target).siblings().animate({ width: '0%', opacity: 0 });
        $(event.target).animate({ width: '100%' }).prop('disabled', true);
        $('.projectModal').fadeOut()
        checkConnectorStatus(props.activeDevice.assetId, props.activeDevice.connectorId, localStorage.getItem('phoneNumber'), localStorage.getItem('userId')).then((resp) => {
            if (resp.status === 200 && (resp.connectorStatus.toLowerCase() === "available" || resp.connectorStatus.toLowerCase() === "preparing")) {
                setTimerDirection(-1);
                setStartTime(900000);
                $('.deviceButtons').fadeToggle(() => {
                    $('.sessionButtons').fadeIn();
                    $('.reserveInputs').fadeIn(500);
                    $('.transactionButton').html('Start Session');
                });
                $('#divConfirmOrDeny').fadeOut(500, () => {
                    let height = (window.screen.width > 430) ? "310.4px" : "257.4px";
                    height = (window.screen.height > 640) ? height : "238.6px";
                    $('#modalStationDetails').animate({ height: height, maxHeight: "100%" }, 200, () => {
                        $('#stationSessionManagement').fadeIn();
                        $('.minimiseButton').show();
                        $('.cancelButton').hide();
                        $('.minimiseButton').css('right', '10px');
                        $(".meterContainer").fadeIn();
                        $('.reserveOptionsContainer').hide();
                    });
                });
                scene = "transaction";
                props.setActiveDevice({
                    ...props.activeDevice,
                    reservedTime: 900000,
                })
                props.reservedUser.current = localStorage.getItem("userId");
                createRealTimeSocket();
            }
            else if (resp.status === 402) {
                $('.reserveButton').addClass('walletInsufficient').removeClass('available').html('Insufficient Balance').prop('disabled', true);
                $('#divConfirmOrDeny').fadeOut(500, () => {
                    let height = (window.screen.width > 430) ? "247.4px" : "195px";
                    height = (window.screen.height > 640) ? height : "160px";
                    $('#modalStationDetails').animate({ height: height, maxHeight: "100%" }, 200, () => {
                        $('#stationSessionManagement').fadeIn();
                        $('.projectModal').hide();
                        $('.reserveOptionsContainer').hide();
                        setTimeout(() => {
                            closeDeviceModal();
                            navigate(matches[1].pathname + '/wallet');
                            var nextHeight = $(window).height() * 0.93 - 95;
                            $('.accountDetailsContainer').css({ 'display': 'flex', 'z-index': 998 });
                            $('.accountDetailsModal').css('display', 'flex');
                            $('.accountDetailsModal').animate({ height: nextHeight, maxHeight: "100%" });
                        }, 2000);
                    });
                });
            } else if (resp.status === 403) {
                $('#divConfirmOrDenyMsgContainer > p').html('It seems you are unauthorized user, <span>Contact your charger supervisor to get access.</span>');
                $(event.target).animate({ width: '0%' }).css('display', 'none').prop('disabled', true).html('Confirm').siblings().animate({ width: '100%', opacity: 1 }).html('Back');
                $('#divConfirmOrDenyMsgContainer').css('background', '#fde9e7').children('i').css('color', '#C44B4B')
                console.log(resp);
            }
            else {
                props.updateProjects().then(() => {
                    $('#divConfirmOrDeny').fadeOut(500, () => {
                        $('#stationSessionManagement').fadeIn();
                        $('.projectModal').fadeIn();
                        $('#modalStationDetails').css({ height: "auto" })
                    });
                });

            }

            if (resp.status !== 403) {
                $(event.target).animate({ width: '76%' }).prop('disabled', false);
                $(event.target).siblings().animate({ width: '22%', opacity: 1 });
                $(event.target).html('Confirm');
            }
        })
    }
    function handleMeterEndSessionMetrics(resp) {
        let now = Date.now();
        let duration = now - resp.session.initiationDate * 1000;
        let seconds = Math.floor((duration / 1000) % 60),
            minutes = Math.floor((duration / (1000 * 60)) % 60),
            hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;

        duration = hours + ":" + minutes + ":" + seconds;
        let energy = (Number(resp.EnergyImport) / 1000).toFixed(2) + ' kwh'
        let date = new Date(resp.session.initiationDate * 1000)
        let date_str = date.toISOString(),
            options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' },
            formatted = (new Date(date_str)).toLocaleDateString('en-US', options),
            date_parts = formatted.substring(0, formatted.indexOf(",")).split(" ").reverse().join(" ");
        let startTime = date_parts + formatted.substr(formatted.indexOf(",") + 1);

        let tariffNumber = resp.tariff ? resp.tariff : 5;
        let tariff = resp.tariff || 5;
        let serviceFee = resp.serviceFee || 0;
        let fee = resp?.chargingFee || 0;
        let reservationFee = resp.reservationFee || 0;
        let amt = (serviceFee + fee) - reservationFee;
        amt = amt > 0 ? amt : 0;
        let amount = (amt || 0);
        let paymentStatus = 'Success';
        let paymentMethod = 'E-Wallet';
        return [duration, energy, startTime, '₹' + parseFloat(tariff).toFixed(2), '₹' + parseFloat(serviceFee).toFixed(2), '₹' + parseFloat(Math.round((fee + Number.EPSILON) * 100) / 100).toFixed(2), "₹-" + parseFloat(reservationFee).toFixed(2), '₹' + parseFloat(amount).toFixed(2), paymentStatus, paymentMethod]
    }
    function handleTransactionButton() {
        if (deviceState === 'charging' || $('.transactionButton').hasClass('active')) {
            $('#stationSessionManagement').fadeOut(500, () => {
                let height = (window.screen.width > 430) ? "140.8px" : "146px";
                height = (window.screen.height > 640) ? height : "133px";
                $('.deviceModal').animate({ height: height }, 200, () => {
                    $('.cancelButton').hide();
                    $('.minimiseButton').hide();
                    $('#divEndSession').fadeIn();
                    $('#divEndSession > .deviceButtons').fadeIn();
                    $('.projectModal').fadeOut();
                });
            });
        }
        else if (deviceState === 'preparing' || deviceState === 'available') {
            setstopwatchState('reset');
            setTimerDirection(1);
            setStartTime(1);
            $('.energyOutput>span').html(0);
            $('.transactionButton').html('Initiating Session').prop('disabled', true);
            remoteStart(props.activeDevice.assetId, props.activeDevice.connectorId).then((res) => {
                if (res.status === 200) {
                    console.log('Device Started');
                    $('.cancelButton').hide();
                    $('.minimiseButton').css('right', '10px').show();
                    interval.current = setTimeout(() => {
                        if (!localStorage.getItem('activeSession')) {
                            if (socket) socket.close();
                            $('.sessionButtons').hide();
                            $('.transactionMessage').css('display', 'flex');
                            $('.cancelButton').show();
                            $('.minimiseButton').hide();
                        }
                    }, 60000)
                    props.reservedUser.current = null;
                }
                else if (res.status === 402) {
                    $('.status').removeClass('active preparing inactive').addClass('inactive');
                    setDeviceState('walletInsufficient');
                    setstopwatchState('pause');
                    $('.cancelButton').show();
                    $('.minimiseButton').css('right', '40px').show();
                }
                else {
                    setDeviceState('unavailable');
                    $('.cancelButton').show();
                    $('.minimiseButton').css('right', '40px').show();
                }
            }).catch(err => {
                console.log(err);
                setDeviceState('unavailable');
                $('.cancelButton').show();
                $('.minimiseButton').css('right', '40px').show();
            })
        }
        else {
            console.log('Invalid Key Press')
        }
    }
    function endSession(event = null, message = null) {
        let endInvoice;
        if (event) {
            if (!activeSession) {
                return;
            }
            $(event.target).html('<i class="fa-solid fa-spinner fa-spin"></i>');
            $(event.target).siblings().animate({ width: '0%', opacity: 0 });
            $(event.target).animate({ width: '100%' }).prop('disabled', true);
        }
        getMeterValues(activeSession?._id || localStorage.getItem('activeSession')).then(resp => {
            let respMod = handleMeterEndSessionMetrics(resp);
            endInvoice = {
                duration: respMod[0],
                energy: respMod[1],
                startTime: respMod[2],
                tariff: respMod[3],
                serviceFee: respMod[4],
                fee: respMod[5],
                reservationFee: respMod[6],
                amount: respMod[7],
                paymentStatus: respMod[8],
                paymentMethod: respMod[9],
                message: message,
            }
            setInvoice(endInvoice);

            if (resp.status === 200) {
                let energy = (Number(resp.EnergyImport) / 1000).toFixed(2);
                $('.energyOutput>span').html(energy);
            }
            scene = "device";
            if (event) {
                $('.transactionButton').html('Stopping Session');
                remoteStop(props.activeDevice.assetId, activeSession.initiationDate, props.activeDevice.connectorId).then((res) => {
                    if (res.status === 202) {
                        setInvoice({ ...endInvoice, message: "This charger is currently offline, <span>your bill payment is pending.</span> Don't worry; once the charger is back online, your payment will be processed.", paymentStatus: "Pending", fee: "Pending", amount: "Pending" });
                    }
                    setTransactionState(false);
                    setActiveSession(null);
                    $('.status').removeClass('active preparing inactive').addClass('inactive');
                    setDeviceState('end');
                    localStorage.removeItem('activeSession');
                    $('.deviceMetrics').hide(() => {
                        $('#divEndSession').fadeOut(500);
                        $('.projectModal').hide();
                        $('.sessionButtons').hide(() => {
                            $('.deviceModal').css("height", "auto").fadeIn(() => {
                                $('.stationInvoiceContainer').fadeIn().animate({ height: "100%", opacity: 1 });
                                setstopwatchState('pause');
                                $('#stationSessionManagement').fadeIn(500);
                            });
                        });
                        $('.deviceButtons').hide();
                        $('.cancelButton').show();
                        $('.minimiseButton').hide();
                    });
                }).catch(err => {
                    console.log(err);
                })
            } else {
                setTransactionState(false);
                setActiveSession(null);
                $('.status').removeClass('active preparing inactive').addClass('inactive');
                setDeviceState('end');
                $('.deviceMetrics').hide(() => {
                    $('.projectModal').hide();
                    $('#divEndSession').fadeOut(500);
                    $('.sessionButtons').hide(() => {
                        $('.deviceModal').css("height", "auto").fadeIn(() => {
                            $('.stationInvoiceContainer').fadeIn().animate({ height: "100%", opacity: 1 });
                            setstopwatchState('pause');
                            $('#stationSessionManagement').fadeIn(500);
                        });
                    });
                    $('.deviceButtons').hide();
                    $('.cancelButton').show();
                    $('.minimiseButton').hide();
                });
                localStorage.removeItem('activeSession');
            }
        }).catch(err => {
            console.log(err);
        })
    }
    function handleTransactionState() {
        if (deviceState === 'charging' || deviceState === 'suspendedevse' || deviceState === 'suspendedev' || deviceState === 'reserved' || (deviceState === 'disconnected' && localStorage.getItem('activeSession'))) {
            setstopwatchState('start')
            if (localStorage.getItem('activeSession'))
                $('.reserveOptionsContainer').hide();
            $('.endSessionButton').animate({ width: '0%', opacity: 0 }, () => {
                $('.endSessionButton').hide();
                $('.status').removeClass('active preparing inactive').addClass('active')
            }).hide();
            $('.transactionButton').html('Stop Session').removeClass('active preparing disabled').prop('disabled', false).addClass('active')
            $('.transactionButton').animate({ width: '100%' }, "fast", "linear");

        }
        // else if (deviceState === 'available') {
        //     $('.energyOutput>span').html(0);
        //     $('.transactionButton').removeClass('active preparing disabled').addClass('disabled');
        //     $('.transactionButton').html('INSERT CONNECTOR TO EV');
        // }
        else if (deviceState === 'available' || deviceState === 'preparing' || (deviceState === 'disconnected' && !localStorage.getItem('activeSession'))) {
            $('.transactionButton').removeClass('active preparing disabled');
            $('.status').removeClass('active preparing inactive').addClass('preparing')
        }
        else if (deviceState === 'finishing') {
            $('.transactionButton').removeClass('active preparing disabled').addClass('active');
            $('.status').removeClass('active preparing inactive').addClass('active')
            $('.transactionButton').html('Session is Finishing');
        }
        else if (deviceState === 'end') {
            // $('.transactionButton').animate({ width: "35%" });
            // $('.endSessionButton').show('slow').animate({ width: "62%", opacity: 1 }, () => {
            //     $('.transactionButton').html('New Session')
            //     $('.status').removeClass('active preparing inactive').addClass('inactive')
            //     $('.transactionButton').removeClass('active preparing disabled').addClass("disabled");
            // });
        }
        else if (deviceState === 'walletInsufficient') {
            $('.transactionButton').html('Insufficient Wallet Balance');
            $('.status').removeClass('active preparing inactive').addClass('inactive')
            $('.transactionButton').removeClass('active preparing disabled').addClass("disabled");
        }
        else {
            $('.transactionButton').removeClass('active preparing disabled').addClass('disabled')
            $('.status').removeClass('active preparing inactive');
            $('.transactionButton').html('Device on Standby');
        }
    }
    async function handleDirectionsButton() {
        $('.directionsButton').html('<i class="fa-solid fa-spinner fa-spin"></i>');
        let dir = await props.getReverseGeocode(direc.current[0], direc.current[1])
        $('#stationSessionManagement').fadeOut(500, () => {
            setDirections(dir.map((el, i) => {
                let instruction = el.maneuver.instruction.toLowerCase();
                let icon;
                if (instruction.includes('u-turn'))
                    return (
                        <div key={i} className='card' style={{ height: '60px', padding: '0px', marginBottom: '5px', borderBottom: '1px solid rgb(190 190 190)', borderRadius: '0', boxShadow: 'none' }}>
                            <div style={{ display: 'flex', color: '#121f62', alignItems: 'center', width: '100%' }}>
                                <p style={{ width: '10%', display: 'flex', alignItems: 'center', borderRight: '2px solid #004982', height: '50px', minWidth: '59px' }}><img src={require('../../assets/deviceModal/uturn.png')}></img></p>
                                <div style={{ padding: '0 10px 0 20px', fontSize: '11px', minWidth: '92px' }}>
                                    <p><i className="fa-solid fa-truck-fast"></i> {el.distance >= 1000 ? (el.distance / 1000).toFixed(2) + ' KM' : ~~el.distance + ' m'}</p>
                                    <p><i className="fa-solid fa-clock"></i> {el.duration >= 3600 ? Math.round(el.duration / 3600) + ' Hours' : el.duration >= 60 ? Math.round(el.duration / 60) + ' Minutes' : Math.round(el.duration) + ' Seconds'}</p>
                                </div>
                                <p style={{ marginTop: '0', marginBottom: '0', marginRight: '10px', marginLeft: '10px', lineHeight: '1.5em', display: 'flex', alignItems: 'center', fontSize: '13px', fontWeight: '600' }}><span> {el.maneuver.instruction}</span></p>
                            </div>
                        </div>)
                else if (instruction.includes("bear") || instruction.includes("keep"))
                    icon = "fa-solid fa-up-long";
                else if (instruction.includes("left"))
                    icon = "fa-solid fa-left-long";
                else if (instruction.includes("right"))
                    icon = "fa-solid fa-right-long";
                else
                    icon = "fa-solid fa-up-long";
                return (
                    <div key={i} className='card' style={{ height: '60px', padding: '0px', marginBottom: '5px', borderBottom: '1px solid rgb(190 190 190)', borderRadius: '0', boxShadow: 'none' }}>
                        <div style={{ display: 'flex', color: '#121f62', alignItems: 'center', width: '100%' }}>
                            <p style={{ width: '10%', display: 'flex', alignItems: 'center', borderRight: '2px solid #004982', height: '50px', minWidth: '59px' }}><i className={icon} style={{ width: '40%', margin: 'auto', fontSize: '19px', textAlign: 'center', color: '#1c487e' }}></i></p>
                            <div style={{ padding: '0 10px 0 20px', fontSize: '11px', minWidth: '92px' }}>
                                <p><i className="fa-solid fa-truck-fast"></i> {el.distance >= 1000 ? (el.distance / 1000).toFixed(2) + ' KM' : ~~el.distance + ' m'}</p>
                                <p><i className="fa-solid fa-clock"></i> {el.duration >= 3600 ? Math.round(el.duration / 3600) + ' Hours' : el.duration >= 60 ? Math.round(el.duration / 60) + ' Minutes' : Math.round(el.duration) + ' Seconds'}</p>
                            </div>
                            <p style={{ marginTop: '0', marginBottom: '0', marginRight: '10px', marginLeft: '10px', lineHeight: '1.5em', display: 'flex', alignItems: 'center', fontSize: '13px', fontWeight: '600' }}><span> {el.maneuver.instruction}</span></p>
                        </div>
                    </div>)
            }))
            $('#divDirections').fadeIn();
            $('.cancelButton').fadeOut();
            $('.minimiseButton').fadeOut();
            $('.directionsBackButton').fadeIn();
        });
        $('.projectModal').fadeOut()
    }
    function handleConnector(e) {
        $(e.target).addClass('selected');
        $(e.target).siblings('.selected').removeClass('selected')
        selectedConnector.current = parseInt($(e.target).attr('data-connectornumber'))
        props.setActiveDevice({
            ...props.activeDevice,
            connectorId: selectedConnector.current,
            chargeStatus: props.activeDevice.connectors[selectedConnector.current - 1]?.chargingStatus.toLowerCase(),
            connectorType: props.activeDevice.connectors[selectedConnector.current - 1]?.connectorType,
            capacity: props.activeDevice.connectors[selectedConnector.current - 1]?.outputWattCategory,
            reservedTime: props.activeDevice.connectors[selectedConnector.current - 1]?.reservedTime,
        })
        props.reservedUser.current = props.activeDevice.connectors[selectedConnector.current - 1]?.reservedUser;
    }

    function cancelSession(event) {
        if (event.detail !== 1)
            return;
        if (event.target.innerHTML === "Close") {
            closeDeviceModal();
            return;
        }
        $(event.target).html('<i class="fa-solid fa-spinner fa-spin"></i>');
        $(event.target).siblings().animate({ width: '0%', opacity: 0 });
        $(event.target).animate({ width: '100%' }).prop('disabled', true);
        $('.projectModal').fadeOut()
        cancelSessionAPI(props.activeDevice.assetId).then((resp) => {
            if (resp.status === 200) {
                props.updateProjects().then(() => {

                    $(event.target).html('Close');
                    $(event.target).prop('disabled', false);

                    // $('.cancelButton').show();
                    $("#divCancelSession > #divEndSessionMsgContainer > p").html("<span>Your reservation has been revoked.</span> You can close this popup.");
                    props.setActiveDevice({
                        ...props.activeDevice,
                        reservedUser: null,
                    });
                    props.reservedUser.current = null;
                    // $("#modalStationDetails").height(75);
                    // closeDeviceModal();
                });
            }
        });
    }

    function calculatePrice(capacity, tariff, price, reservationFee) {
        return (capacity * (price + tariff) + reservationFee).toFixed(2);
    }
    useEffect(() => {
        if (activeSession && activeSession?.status !== 'closed') {
            setTransactionState(true);
            scene = "transaction"
        }
        else {
            console.log('Waiting for Active Session Data');
        }
    }, [activeSession])
    useEffect(() => {
        handleTransactionState();
    }, [deviceState])
    useEffect(() => {
        direc.current = [props.activeDevice.coordinates[0], props.activeDevice.coordinates[1]]
        selectedConnector.current = props.activeDevice?.connectorId || 1;
        if (!localStorage.getItem('activeSession') && props.reservedUser.current !== localStorage.getItem("userId") && socket?.readyState !== 1 && !invoice) resetModal();
        setRerender(!rerender);
    }, [props.activeDevice])
    // useEffect(() => {
    //     if (props.loaded && _activeSession.current) {
    //         props.hideProjects();
    //     }
    // }, [props.loaded])
    useEffect(() => {
        handleTransactionState();
        if (localStorage.getItem('activeSession')) {
            getSession(localStorage.getItem('activeSession')).then(resp => {
                if (resp.status === 200) {
                    if (resp.chargingStation._id === props.activeDevice.assetId && resp.session.status === 'open') {
                        setActiveSession(resp.session);
                        scene = "transaction";
                        _activeSession.current = resp.session;
                        let now = Date.now() / 1000;
                        setTimerDirection(1);
                        setStartTime(Math.floor((now - resp.session.initiationDate) * 1000))
                        $('.sessionButtons').fadeIn(0);
                        $('.reserveInputs').fadeIn(0);
                        $('.cancelButton').hide();
                        $('.minimiseButton').hide();
                        $('.expandButton').show();
                        $('.projectModal').fadeOut(0);
                        $(".reserveOptionsContainer").fadeOut(0);
                        $('.directions').fadeOut(0);
                        $('.deviceMetrics').fadeOut(0);
                        $('.sessionButtons').fadeOut(0);
                        $('.deviceButtons').fadeToggle(0);
                        createRealTimeSocket();
                        let energyImport = resp.session.meterValues[resp.session.meterValues.length - 1]?.filter(e => (e.unit === 'Wh'))[0].value - resp.session.meterValues[0]?.filter(e => (e.unit === 'Wh'))[0].value;
                        $('.energyOutput>span').html((energyImport / 1000).toFixed(2));
                    }
                }
                else {
                    console.log('Invalid Session Id');
                }
            })
        } else if (props.reservedUser.current === localStorage.getItem("userId")) {
            console.log("Reservation code executed!");
            scene = "transaction";
            let now = Date.now();
            setTimerDirection(-1);
            setStartTime(Math.floor((900000 - (now - new Date(props.activeDevice.reservedTime).getTime()))))
            $('.sessionButtons').fadeIn(0);
            $('.reserveInputs').fadeIn(0);
            $('.cancelButton').hide();
            $('.minimiseButton').hide();
            $('.expandButton').show();
            $('.projectModal').fadeOut(0);
            $(".reserveOptionsContainer").fadeOut(0);
            $('.directions').fadeOut(0);
            $('.deviceMetrics').fadeOut(0);
            $('.sessionButtons').fadeOut(0);
            $('.deviceButtons').fadeToggle(0);
            createRealTimeSocket();
            $('.energyOutput>span').html((0).toFixed(2));
        }
        let types = ['type2', 'chademo', 'type1', '3pin', 'ccs1', 'ccs2']
        let availability = {
            'available': 'availableConnector',
            'faulted': 'faultedConnector',
            'unavailable': 'unavailableConnector',
        }
        setConnectors(props.activeDevice.connectors.map((e, i) => {
            return (
                <div key={i} className={'optionsContainer ' + (e.connectorNumber === (props?.activeDevice?.connectorId || 1) ? 'selected ' : '') + (availability[(e.reservedUser) ? "charging" : e.chargingStatus.toLowerCase()])} data-connectornumber={e.connectorNumber} onClick={handleConnector}>
                    <p>{chargerTypes[e.connectorType.toLowerCase()]}</p>
                    <img src={types.includes(e.connectorType.toLowerCase()) ? require('../../assets/deviceModal/' + e.connectorType.toLowerCase() + '.svg') : require('../../assets/deviceModal/default.png')}></img>
                </div>
            )
        }));
        $('.directionsButton').prop('disabled', props.currentLocation.length !== 2);
        $('.directionsButton').on('click', handleDirectionsButton)
        $('#divDirections').hide();
        $('#divConfirmOrDeny').hide();
        $('#divEndSession').hide();
        $('.directionsBackButton').hide();
        // if($('.reserveButton').html() === 'Unauthorized User'){
        //     resetModal()
        // }
        return (() => {
            props.removePath();
        })
    }, [])
    return (
        <div id="modalStationDetails" className='deviceModal'>
            <i className="fa-regular fa-circle-xmark cancelButton" onClick={() => { closeDeviceModal() }}></i>
            <i className="fa-solid fa-circle-chevron-down minimiseButton" onClick={() => { handleMinimiseButton() }}></i>
            <i className="fa-solid fa-circle-chevron-up expandButton" onClick={() => { handleExpandButton() }}></i>
            <div id="stationSessionManagement">
                <div className="deviceInfo">
                    <div>
                        <h1>{props.activeDevice.name}</h1>
                        {props.activeDevice && <span className={"badge badge-pill badge-dark " + props.activeDevice.accessCategory}>Private</span>}
                        <p className={deviceState ? (deviceState + ' status') : (props.activeDevice.chargeStatus + ' status')}><i style={{ marginRight: "7px" }} className="fa-solid fa-circle-dot"></i>{deviceState}</p>
                    </div>
                    <p id='pDirectionsToCharger' className='directions'>{props.activeDevice.location}</p>
                </div>
                <div className="deviceMetrics">
                    <div className='metricContainer'>
                        <p>Capacity</p>
                        <p>(kWh)</p>
                        <p>{props.activeDevice.connectors[props.activeDevice.connectorId - 1]?.outputWattCategory || "N/A"}</p>
                    </div>
                    <div className='metricContainer'>
                        <p>Tariff Fee</p>
                        <p>(per kWh)</p>
                        <p>₹{props.activeDevice.pricingPolicy ? props.activeDevice.pricingPolicy?.price + (props.activeDevice?.project?.sessionTariff?.price || 0) : null}</p>
                    </div>
                    <div className='metricContainer'>
                        <p>Reservation</p>
                        <p>Fee</p>
                        <p>₹{props.activeDevice.project?.reservationFee.price || 0}</p>
                    </div>
                    <div className='metricContainer'>
                        <p>Minimum</p>
                        <p>Balance</p>
                        <p>₹{calculatePrice(parseFloat(props.activeDevice.connectors[props.activeDevice.connectorId - 1]?.outputWattCategory) || 0, props.activeDevice.pricingPolicy?.price || 0, props.activeDevice?.project?.sessionTariff?.price || 0, props.activeDevice.project?.reservationFee.price || 0)}</p>
                    </div>
                </div>
                <div className='reserveOptionsContainer'>
                    <p>Select One</p>
                    <div id="divConnectorSelector" className="deviceOptions">
                        {connectors}
                    </div>
                    <input className='hide' id="connectorId" name="connectorId" type="number" />
                </div>
                <div className='deviceEnabledButtons reserveInputs'>
                    <div className='sessionButtons'>
                        <button className='transactionButton' onClick={handleTransactionButton}> Start Session </button>
                        <button className='endSessionButton' onClick={() => {
                            $('#stationSessionManagement').fadeOut(500, () => {
                                let height = (window.screen.width > 430) ? "140.8px" : "146px";
                                height = (window.screen.height > 640) ? height : "133px";
                                $('.deviceModal').animate({ height: height }, 200, () => {
                                    $('.cancelButton').hide();
                                    $('.minimiseButton').hide();
                                    $('#divCancelSession').fadeIn();
                                    $('#divCancelSession > .deviceButtons').fadeIn();
                                    $('.projectModal').fadeOut();
                                });
                            });
                        }}> Cancel Session </button>
                    </div>
                    <div className="transactionMessage">
                        <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                        <p>It seems your session hasn't begun.
                            <span> Please refresh or contact your supervisor.</span>
                        </p>
                    </div>
                    <div className='meterContainer'>
                        <div className='status'><p>{deviceState}</p><i className="fa-solid fa-bolt"></i></div>
                        <div className='divider'></div>
                        <div className='timer'>{<Stopwatch command={stopwatchState} startTime={startTime} direction={timerDirection} />}</div>
                        <div className='divider'></div>
                        <div className='energyOutput'><span>0</span> kWh</div>
                    </div>
                </div>
                <div className="deviceButtons">
                    <button className={'reserveButton ' + (props.reservedUser.current ? 'charging' : (props.activeDevice.chargeStatus === 'preparing' ? 'available' : props.activeDevice.chargeStatus))} onClick={() => {
                        $('#stationSessionManagement').fadeOut(500, () => {
                            let height = (window.screen.width > 430) ? "140.8px" : "134.8px";
                            height = (window.screen.height > 640) ? height : "121.8px";
                            $('#divConfirmOrDenyMsgContainer').removeAttr('style').children().removeAttr('style');
                            $('#divConfirmOrDenyButtons').removeAttr('style').children().removeAttr('style').removeAttr('disabled');
                            $('#divConfirmOrDenyMsgContainer > p').html(`By confirming reservation, you accept to pay <span>Rs.${(props.activeDevice.project?.reservationFee.price || 0)}</span> of parking fee from your wallet`)
                            $('#modalStationDetails').animate({ height: height, maxHeight: "100%" }, 200, () => {
                                $('.minimiseButton').hide()
                                $('#divConfirmOrDeny').fadeIn();
                                $('.projectModal').fadeOut()
                            });
                        });
                    }} disabled={(!props.reservedUser.current && (props.activeDevice.chargeStatus === 'available' || props.activeDevice.chargeStatus === 'preparing')) ? '' : 'disabled'}> {(!props.reservedUser.current && (props.activeDevice.chargeStatus === 'available' || props.activeDevice.chargeStatus === 'preparing')) ? 'Reserve Spot' : 'Device Currently Unavailable'} </button>
                    <button className='directionsButton' disabled={true}> <i className="fa-solid fa-diamond-turn-right"></i> {(distance || '-') + ' KM'}</button>
                </div>
            </div>
            {
                invoice && <div>
                    <Invoice details={invoice} />
                </div>
            }
            <div id="divDirections">
                {/* onClick={() => {
                    $('#divDirections').fadeOut(500, () => {
                        $('#stationSessionManagement').fadeIn()
                        $('.cancelButton').fadeIn()
                        $('.directionsBackButton').fadeOut();
                        $('.projectModal').fadeIn()
                    })
                }} */}
                <p className="directionsBackButton"><i className="fa-regular fa-circle-xmark" onClick={() => {
                    props.removePath();
                    $('#divDirections').fadeOut(500, () => {
                        $('#stationSessionManagement').fadeIn()
                        $('.cancelButton').fadeIn()
                        $('.minimiseButton').fadeIn()
                        $('.directionsBackButton').fadeOut();
                        $('.projectModal').fadeIn()
                    })
                    $('.directionsButton').html(' <i className="fa-solid fa-diamond-turn-right"></i> ' + (distance || '-') + ' KM');
                }}></i> Directions</p>
                <div>
                    {directions}
                </div>
            </div>
            <div id="divConfirmOrDeny">
                <h3>Confirmation</h3>
                <div id='divConfirmOrDenyMsgContainer' className='info'><i className='fa fa-info-circle'></i> <p>By confirming reservation, you accept to pay <span>Rs.{props.activeDevice.project?.reservationFee.price || 0}</span> of parking fee from your wallet</p></div>
                <div id='divConfirmOrDenyButtons' className='deviceButtons'>
                    <button className='confirmButton' onClick={handleReserveButton}>Confirm</button>
                    <button className='denyButton' onClick={() => {
                        $('#divConfirmOrDeny').fadeOut(500, () => {
                            let height = (window.screen.width > 430) ? "336.4px" : "271.2px";
                            height = (window.screen.height > 640) ? height : "238.6px";
                            $('#modalStationDetails').animate({ height: height, maxHeight: "100%" }, 200, () => {
                                $('#stationSessionManagement').fadeIn();
                                $('.projectModal').fadeIn();
                                $('.minimiseButton').show()
                            });
                        });
                    }} >Deny</button>
                </div>
            </div>
            <div id="divEndSession">
                <h3>Confirmation</h3>
                <div id='divEndSessionMsgContainer' className='info'><i className='fa fa-exclamation-circle'></i> <p>By clicking confirm, you are going to <span>end the session. </span>Are you sure?</p></div>
                <div id='divEndSessionButtons' className='deviceButtons'>
                    <button onClick={(e) => {
                        endSession(e);
                    }}>Confirm</button>
                    <button onClick={() => {
                        $('#divEndSession').fadeOut(500, () => {
                            let height = (window.screen.width > 430) ? "310.4px" : "257.4px";
                            height = (window.screen.height > 640) ? height : "238.6px";
                            $('#modalStationDetails').animate({ height: height, maxHeight: "100%" }, 200, () => {
                                $('#stationSessionManagement').fadeIn();
                                $('.cancelButton').hide();
                                $('.minimiseButton').css('right', '10px').show();
                            });
                        });
                    }} >Deny</button>
                </div>
            </div>
            <div id="divCancelSession">
                <h3>Confirmation</h3>
                <div id='divEndSessionMsgContainer' className='info'><i className='fa fa-exclamation-circle'></i> <p>By clicking confirm, you are going to <span>cancel the reservation.</span> Are you sure?</p></div>
                <div id='divEndSessionButtons' className='deviceButtons'>
                    <button onClick={(e) => {
                        cancelSession(e);
                    }}>Confirm</button>
                    <button onClick={() => {
                        $('#divCancelSession').fadeOut(500, () => {
                            let height = (window.screen.width > 430) ? "310.4px" : "257.4px";
                            height = (window.screen.height > 640) ? height : "238.6px";
                            $('#modalStationDetails').animate({ height: height, maxHeight: "100%" }, 200, () => {
                                $('#stationSessionManagement').fadeIn();
                                $('.cancelButton').hide();
                                $('.minimiseButton').css('right', '10px').show();
                            });
                        });
                    }} >Deny</button>
                </div>
            </div>
        </div >
    )
}

export default DeviceModal